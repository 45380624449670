<section class="basket-container">
  <section class="basket-header">
    <section class="row justify-content-around">
      <h1 class="basket-title-text col-6">{{'BASKET.MY_BASKET' | translate}}</h1>
      <p class="close-basket-link col-6" (click)="service.showBasket = false">{{'BASKET.CLOSE' | translate}}
        <span><img src="../../assets/images/close_arrow.svg" alt="" class="close-arrow"></span>
      </p>
    </section>
  </section>

  <section class="basket-body">
    <div *ngIf="isBasketEmpty">
      <h1 class="empty-basket-text">{{'BASKET.EMPTY_BASKET' | translate}}</h1>
    </div>

    <section class="basket-item-container" *ngFor="let product of productsInBasket">
      <div class="close-x-button" (click)="removeItem(product.cart_id)">
        <img src="../../assets/images/close_x.svg" alt="" class="close-x">
      </div>

      <section class="row">
        <div class="image-wrapper col-12 col-lg-6">
          <img src="{{product.images.webp}}" alt="" class="item-thumbnail">
        </div>

        <div class="data-wrapper col-12 col-lg-6">
          <h2 class="item-title">{{product.name}}</h2>
          <p *ngIf="getProductOption(product)" class="quantity">{{product.option[0].name}}
            : {{product.option[0].value}}</p>
          <div class="increase-decrease-wrapper col-12 col-lg-6">
            <button class="add-button" (click)="modifyItem(product.cart_id, product.quantity - 1)">
              <img src="../../assets/images/minus_button.svg" alt="minus">
            </button>
            <p class="item-count">{{product.quantity}}</p>
            <button class="remove-button" (click)="modifyItem(product.cart_id, product.quantity + 1)">
              <img src="../../assets/images/plus_button.svg" alt="plus">
            </button>
          </div>
        </div>

        <div class="price-wrapper col-12 col-lg-6">
          <p class="item-price" *ngIf="product && !product.formatted_prices.discount_percentage">
            {{product.formatted_prices.unit_price}}</p>
          <p class="item-price-old" *ngIf="product && product.formatted_prices.discount_percentage">
            {{product.formatted_prices.unit_price}}</p>
          <p class="item-price-new" *ngIf="product && product.formatted_prices.discount_percentage">
            {{product.formatted_prices.on_sale_price}}</p>
        </div>
      </section>
    </section>
  </section>

  <section class="basket-footer">
    <section class="row justify-content-around">
      <p *ngIf="coupon" class="total-item-text col-6">{{'BASKET.PRICE_OF_ITEMS_ORIGINAL' | translate}}</p>
      <p *ngIf="coupon" class="total-item-price col-6">{{totalPriceOriginal}}</p>

      <p *ngIf="coupon" class="total-item-text col-6">{{'BASKET.COUPON_OFF' | translate}}</p>
      <p *ngIf="coupon" class="total-item-price col-6">{{couponOff}}</p>

      <p class="total-item-text col-6">{{'BASKET.PRICE_OF_ITEMS' | translate}}</p>
      <p class="total-item-price col-6">{{totalPrice}}</p>
    </section>
    <button class="checkout-button col-12" (click)="getBasket()">{{'BUTTON.REFRESH_BASKET' | translate}}</button>
    <button class="checkout-button col-12" (click)="routerGuard()">{{'BUTTON.TO_PAYMENT' | translate}}</button>
  </section>

</section>
