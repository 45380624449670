<section class="space-container">
<section class="container">
  <section class="row justify-content-center">
    <section class="summarize-product col-12">
      <section class="info-wrapper row">
        <section class="images col-12 col-lg-6">
          <img *ngIf="mediaType ==='img' " class="preview-product-image col-12" src="{{showImage()}}">
          <video controls *ngIf="mediaType ==='video'" class="preview-product-image col-12"
                 src="{{showVideo()}}" [controls]="false" autoplay loop></video>
          <section class="small-images-wrapper row">
            <img *ngFor="let image of images; index as i" (click)="selectedImage = i; getType(image.sources.webp)"
                 class="product-sm-image align-self-start col-3"
                 src="{{image.sources.webp}}">
            <!--            <img *ngFor="let video of videos; index as i" (click)="selectedImage = i"
                             class="product-sm-image align-self-start col-3"
                             src="{{video.sources.mp4}}">-->
            <video class="product-sm-image align-self-start col-3" muted *ngFor="let video of videos; index as i"
                   (click)="selectedVideo = i; getType(video)"
                   preload="auto">
              <source src={{video}} type="video/mp4">
            </video>
          </section>
        </section>

        <section class="info col-12 col-lg-6">
          <p class="product-category">{{"PRODUCT.PRODUCTS" | translate}}
            <img src="../../assets/images/category_arrow.svg" alt="" class="index-arrow">
            {{product && product.data.category.name}}
          </p>
          <h1 class="product-title">{{product && product.data.name}}</h1>
          <div class="pink-flags-container">
            <p *ngIf="product && product.data.free_shipping"
               class="flags-pink">{{"PRODUCT.FREE_SHIPPING" | translate}}</p>
            <p *ngIf="product && product.data.formatted_prices.discount_percentage" class="flags-pink">
              - {{product && product.data.formatted_prices.discount_percentage}}{{'PRODUCT.DISCOUNT' |
              translate}}</p>
          </div>

          <div>
            <p class="paragraph-text product-description">
              <ngx-dynamic-hooks [content]="product && product.data.description"></ngx-dynamic-hooks>
            </p>
          </div>

          <section class="thin-line"></section>

          <h1 *ngFor="let option of options" class="packing">{{option.name}}</h1>

          <div class="flags-container">
            <div class="flag-normal" *ngFor="let value of productValues"
                 (click)="defaultSelectedOption = value"
                 [ngClass]=" {'flag-selected' : defaultSelectedOption.name === value.name}">
              {{value.name}} - {{value.price}}
            </div>
<!--            <select class="value-select" [(ngModel)]="defaultSelectedOption">-->
<!--              &lt;!&ndash;              <option selected="selected" disabled> {{"MISC.SELECTED_VALUE" | translate}}</option>&ndash;&gt;-->
<!--              <option *ngFor="let defaultSelectedOption of productValues"-->
<!--                      [ngValue]="defaultSelectedOption">{{defaultSelectedOption.name}}</option>-->
<!--            </select>-->

          </div>

          <!--<div *ngIf="product && product.data.formatted_prices.discount_percentage" class="price-container">
            <p class="price" *ngIf="product && !product.data.formatted_prices.discount_percentage">
              {{defaultSelectedOption && defaultSelectedOption.price}}</p>
            <p class="price-old" *ngIf="product && product.data.formatted_prices.discount_percentage">
              {{product.data.formatted_prices.full_price}}</p>
            <p class="price-new" *ngIf="product && product.data.formatted_prices.discount_percentage">
              {{getDiscountPrice(defaultSelectedOption.price)}} {{"CURRENCY.HUF" | translate}}</p>
          </div>-->

          <div *ngIf="product && product.data.formatted_prices.discount_percentage" class="price-container">
            <p class="price-old" *ngIf="product && product.data.formatted_prices.full_price">{{ product.data.formatted_prices.full_price }}</p>
            <p class="price-new" *ngIf="product && product.data.formatted_prices.on_sale_price">{{ product.data.formatted_prices.on_sale_price }}</p>
          </div>

          <p class="price full-price" *ngIf="product && !product.data.formatted_prices.discount_percentage && product.data.formatted_prices.full_price">{{product.data.formatted_prices.full_price}}</p>


          <section class="button-container row">
            <section class="basket-button-wrapper col-12 col-lg-12 col-xl-4">
              <button class="basket-button" (click)="addToBasket()">
                <span><img src="../../assets/images/cart.svg"
                           alt=""> {{"BUTTON.CART" | translate}}{{'(' + productCounter + ')'}}</span>
              </button>
            </section>

            <section class="plus-minus-wrapper col-12 col-lg-12 col-xl-4">
              <button class="add-button" (click)="productCounter = modifyBasketQuantity('dec', productCounter)">
                <img src="../../assets/images/minus_button.svg"></button>
              <button class="remove-button" (click)="productCounter = modifyBasketQuantity('inc', productCounter)">
                <img src="../../assets/images/plus_button.svg"></button>
            </section>

<!--            <section class="wishlist-wrapper col-12 col-lg-12 col-xl-4">-->
<!--              &lt;!&ndash; Hozzáadni a linkhez és az svg-hez a végleges verzióban: *ngIf="product.wishlist"  &ndash;&gt;-->
<!--              <img *ngIf="wishlist" src="../../assets/images/wishlist.svg">-->
<!--              <img *ngIf="!wishlist" src="../../assets/images/heart.svg">-->
<!--              <a class="wishlist-text" (click)="addToWishlist(wishlist)">{{"BUTTON.WISHLIST" | translate}}</a>-->
<!--            </section>-->
          </section>

          <section class="thin-line"></section>

          <div *ngIf="product && !product.data.in_stock">
            <p *ngIf="product && !product.data.in_stock" class="status"><img class="check-mark"
                                                                            src="../../assets/images/cross.svg"
                                                                            alt="">{{"PRODUCT.OUT_STOCK" | translate}}
            </p>
            <!-- Todo: Egyeztetni Márk-al, hogy hol van az előrendelhetőségről szóló API response -->
<!--            <p class="status"><img class="check-mark" src="../../assets/images/check_mark.svg" alt="">Előrendelhető</p>-->
          </div>
        </section>
      </section>
    </section>
  </section>
  <section class="description-wrapper row justify-content-center">
    <section class="text-wrapper col-12 col-lg-6">
      <section class="product-description-container">
        <section class="row justify-content-center">
          <p class="category col-3" *ngFor="let attribute of attributes"
             (click)="selectedAttribute = attribute.name;"
             [ngClass]="{'selected-category' : selectedAttribute === attribute.name}">{{attribute.name}}</p>
        </section>
      </section>
      <section *ngFor="let attribute of attributes" class="text-container">
        <h1 *ngIf="attribute.name === selectedAttribute" class="packing">
          {{attribute.name}}</h1>
        <p *ngIf="attribute.name === selectedAttribute" class="paragraph-text">
          <ngx-dynamic-hooks [content]="attribute.text"></ngx-dynamic-hooks>
        </p>
      </section>
    </section>

    <section class="desc-image-wrapper col-12 col-lg-6 ">
      <img src="{{product && product.data.images.description_image.sources.webp}}" alt="" class="description-pic">
    </section>
  </section>
</section>
<div class="related-products-wrapper">
  <!--  <app-related-products></app-related-products>-->
</div>
</section>
