import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from 'app-routing.module';
import {DynamicHooksModule, HookParserEntry} from 'ngx-dynamic-hooks';
import {AppComponent} from './app.component';
import {MainComponent} from './main/main.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {LatestBlogsComponent} from './latest-blogs/latest-blogs.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
// @ts-ignore
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SubscribeComponent} from './subscribe/subscribe.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SafeHtmlPipe} from './safe-html.pipe';
import {LoginComponent} from './login/login.component';
import {ProductComponent} from './product/product.component';
import {RelatedProductsComponent} from './related-products/related-products.component';
import {BasketComponent} from './basket/basket.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {PaymentComponent} from './payment/payment.component';
import {ProductListComponent} from './product-list/product-list.component';
import {BlogsComponent} from './blogs/blogs.component';
import {BlogComponent} from './blog/blog.component';
import {CategoryComponent} from './category/category.component';
import {OurValuesComponent} from './our-values/our-values.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {PersistanceService} from './persistance.service';
import {RegisterComponent} from './register/register.component';
import {ComingSoonComponent} from './coming-soon/coming-soon.component';
import {PolicyComponent} from './policy/policy.component';
import {TermsComponent} from './terms/terms.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {CookieComponent} from './cookie/cookie.component';
import {LandingComponent} from './landing/landing.component';
import {CongratsComponent} from './congrats/congrats.component';
import {ErrorComponent} from './error/error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {CancelComponent} from './cancel/cancel.component';
import { ModifyPasswordComponent } from './modify-password/modify-password.component';
import { SimplepayErrorComponent } from './simplepay-error/simplepay-error.component';

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const componentParsers: Array<HookParserEntry> = [
  {component: LatestBlogsComponent},
  {component: BlogComponent},
  {component: MainComponent},
  {component: ProductComponent},
  {component: BlogsComponent},
  {component: CategoryComponent}
];

@NgModule({

  declarations: [
    AppComponent,
    MainComponent,
    NavbarComponent,
    FooterComponent,
    SubscribeComponent,
    SafeHtmlPipe,
    LoginComponent,
    ProductComponent,
    LatestBlogsComponent,
    RelatedProductsComponent,
    BasketComponent,
    CheckoutComponent,
    PaymentComponent,
    ProductListComponent,
    BlogsComponent,
    BlogComponent,
    CategoryComponent,
    OurValuesComponent,
    AboutUsComponent,
    RegisterComponent,
    ComingSoonComponent,
    PolicyComponent,
    TermsComponent,
    ContactUsComponent,
    CookieComponent,
    LandingComponent,
    CongratsComponent,
    ErrorComponent,
    NotFoundComponent,
    CancelComponent,
    ModifyPasswordComponent,
    SimplepayErrorComponent
  ],
  imports: [
    RouterModule.forRoot([
      {path: 'basket', component: BasketComponent}
    ], {onSameUrlNavigation: 'reload'}),
    // forRoot() is used to register global parsers and options
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'hu-hu'
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [MainComponent, NavbarComponent, CategoryComponent, PersistanceService, BasketComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
