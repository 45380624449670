<section class="space-container">
  <div *ngIf="isSubmitted"
       style="position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 99999; display: flex; justify-content: center; padding-top: 70px;">
    <div class="bg-white"
         style="padding: 30px; height: 250px;">
      <img src="assets/images/loading.gif" alt="Spinner"
           style="width: 150px; height: 150px; display: block; margin: 0 auto;">
      <p style="font-size: 24px;">Rendelése feldolgozás alatt..<span [ngClass]="{'white': dot}">.</span></p>
    </div>
  </div>
  <section class="step-container container">
    <section class="row justify-content-center justify-content-lg-start">
      <section class="step-wrapper col-8 col-sm-4 col-lg-3">
        <img [ngClass]="{'current-tab': routeState === 'personal-data'}" class="number-img"
             src={{step01ImgSrc}}>
        <p class="step-text">{{'PAYMENT.PERSONAL_DATA' | translate}}</p>
      </section>

      <section class="step-wrapper col-8 col-sm-4 col-lg-3">
        <img [ngClass]="{'current-tab': routeState === 'shipping-and-payment'}" class="number-img"
             src={{step02ImgSrc}}>
        <p class="step-text">{{'PAYMENT.SHIPPING_AND_PAYMENT' | translate}}</p>
      </section>

      <section class="step-wrapper col-8 col-sm-4 col-lg-3">
        <img [ngClass]="{'current-tab': routeState === 'summary'}" class="number-img" src="assets/images/number_03.svg">
        <p class="step-text">{{'PAYMENT.SUMMARY' | translate}}</p>
      </section>
    </section>
  </section>

  <section *ngIf="routeState === 'personal-data'" class="number-01-container container">
    <section class="coupon-container container">
      <div class="col-lg-9 col-xl-8 row justify-content-between">
        <p class="input-span coupon">{{'PAYMENT.GOT_COUPON' | translate}}</p>
        <div class="coupon-wrapper col-6">
          <input class="input-field" [(ngModel)]="couponCode" type="text"
                 placeholder="{{'PAYMENT.GOT_COUPON_PLACEHOLDER' | translate}}">
        </div>
        <div class="coupon-wrapper col-6">
          <button (click)="applyCoupon()"
                  class="button coupon-button col-6">{{'BUTTON.VALIDATE_COUPON' | translate}}</button>

        </div>
      </div>
    </section>

    <form [formGroup]="personalDataForm">
      <section class="row">
        <section class="col-lg-9 col-xl-8">
          <section class="personal-data-container row">
            <div class="col-12">
              <p class="title-medium">{{'PAYMENT.INFO_ABOUT_YOU' | translate}}</p>
              <p class="required">{{'PAYMENT.REQUIRED_FIELDS' | translate}}</p>
            </div>

            <div class="col-12 col-sm-6">
              <p class="input-span">{{'PAYMENT.SURNAME' | translate}}</p>
              <input class="input-field" formControlName="lastname" type="text"
                     placeholder="{{'PAYMENT.SURNAME_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-6">
              <p class="input-span">{{'PAYMENT.FIRSTNAME' | translate}}</p>
              <input class="input-field" formControlName="firstname" type="text"
                     placeholder="{{'PAYMENT.FIRSTNAME_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-6">
              <p class="input-span">{{'PAYMENT.EMAIL' | translate}}</p>
              <input class="input-field" formControlName="email" type="text"
                     placeholder="{{'PAYMENT.EMAIL_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-6">
              <p class="input-span">{{'PAYMENT.PHONE' | translate}}</p>
              <input class="input-field" formControlName="phone" type="text"
                     placeholder="{{'PAYMENT.PHONE_PLACEHOLDER' | translate}}">
            </div>
          </section>

          <section class="shipping-address-container row">
            <div class="col-12">
              <p class="title-medium">{{'PAYMENT.ADDRESS' | translate}}</p>
            </div>
            <div class="col-12 col-sm-3 col-lg-2">
              <p class="input-span">{{'PAYMENT.ZIPCODE' | translate}}</p>
              <input formControlName="irszam" class="input-field" type="text"
                     placeholder="{{'PAYMENT.ZIPCODE_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-9 col-lg-4">
              <p class="input-span">{{'PAYMENT.CITY' | translate}}</p>
              <input formControlName="city" class="input-field" type="text"
                     placeholder="{{'PAYMENT.CITY_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-9 col-lg-4">
              <p class="input-span">{{'PAYMENT.PUBLIC_PLACE_TYPE' | translate}}</p>
              <input formControlName="cim" class="input-field" type="text"
                     placeholder="{{'PAYMENT.PUBLIC_PLACE_TYPE_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-3 col-lg-2">
              <p class="input-span">{{'PAYMENT.ADDRESS_NUMBER' | translate}}</p>
              <input formControlName="hazszam" class="input-field" type="text"
                     placeholder="{{'PAYMENT.ADDRESS_NUMBER_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-5 col-lg-4">
              <p class="input-span">{{'PAYMENT.OTHER' | translate}}</p>
              <input formControlName="address_2" class="input-field" type="text"
                     placeholder="{{'PAYMENT.OTHER_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-7 col-lg-8">
              <p class="input-span">{{'PAYMENT.COUNTRY' | translate}}</p>
              <select class="input-field input-select">
                <option selected>Magyarország</option>
              </select>
            </div>

<!--            message to currier is hidden due to request-->
<!--            <div class="col-12 col-sm-7 col-lg-8">-->
<!--              <p class="input-span">{{'PAYMENT.NOTE_TO_COURIER' | translate}}</p>-->
<!--              <input formControlName="komment" class="input-field" type="text"-->
<!--                     placeholder="{{'PAYMENT.NOTE_TO_COURIER_PLACEHOLDER' | translate}}">-->
<!--            </div>-->
            <div class="radio-container">
              <section class="radio-wrapper">
                <input type="radio" id="different-billing-address" (click)="toggleDifferentBillButton()">
                <label class="radio-label"
                       for="different-billing-address">{{'PAYMENT.DIFFERENT_BILLING_ADDRESS'| translate}}</label>
              </section>

              <section class="radio-wrapper">
                <input type="radio" id="company-billing-address" (click)="toggleCompanyButton()">
                <label class="radio-label"
                       for="company-billing-address">{{'PAYMENT.COMPANY_BILLING_ADDRESS'| translate}}</label>
              </section>
            </div>
          </section>

          <section *ngIf="differentBillingAddressIsClicked" class="shipping-address-container row">
            <div class="col-12">
              <p class="title-medium">{{'PAYMENT.BILLING_ADDRESS' | translate}}</p>
            </div>
            <div class="col-12 col-sm-3 col-lg-2">
              <p class="input-span">{{'PAYMENT.ZIPCODE' | translate}}</p>
              <input formControlName="billingIrszam" class="input-field" type="text"
                     placeholder="{{'PAYMENT.ZIPCODE_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-9 col-lg-4">
              <p class="input-span">{{'PAYMENT.CITY' | translate}}</p>
              <input formControlName="billingCity" class="input-field" type="text"
                     placeholder="{{'PAYMENT.CITY_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-9 col-lg-4">
              <p class="input-span">{{'PAYMENT.PUBLIC_PLACE_TYPE' | translate}}</p>
              <input formControlName="billingCim" class="input-field" type="text"
                     placeholder="{{'PAYMENT.PUBLIC_PLACE_TYPE_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-3 col-lg-2">
              <p class="input-span">{{'PAYMENT.ADDRESS_NUMBER' | translate}}</p>
              <input formControlName="billingHazszam" class="input-field" type="text"
                     placeholder="{{'PAYMENT.ADDRESS_NUMBER_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-5 col-lg-4">
              <p class="input-span">{{'PAYMENT.OTHER' | translate}}</p>
              <input formControlName="billingAddress_2" class="input-field" type="text"
                     placeholder="{{'PAYMENT.OTHER_PLACEHOLDER' | translate}}">
            </div>
          </section>

          <section *ngIf="companyBillingIsClicked" class="shipping-address-container row">
            <div class="col-12">
              <p class="title-medium">{{'PAYMENT.COMPANY_ADDRESS' | translate}}</p>
            </div>
            <div class="col-12 col-sm-9 col-lg-9">
              <p class="input-span">{{'PAYMENT.FIRM_NAME' | translate}}</p>
              <input formControlName="companyName" class="input-field" type="text"
                     placeholder="{{'PAYMENT.FIRM_NAME_PLACEHOLDER' | translate}}">
            </div>

            <div class="col-12 col-sm-3 col-lg-3">
              <p class="input-span">{{'PAYMENT.FIRM_TAX_ID' | translate}}</p>
              <input formControlName="companyVAT" class="input-field" type="text"
                     placeholder="{{'PAYMENT.FIRM_TAX_ID_PLACEHOLDER' | translate}}">
            </div>
          </section>

          <section class="data-management-wrapper">
            <p class="title-medium">{{'PAYMENT.DATA_MANAGEMENT' | translate}}</p>

            <section class="radio-wrapper">
              <input type="radio" id="radio-policy" formControlName="policyChecked" (click)="togglePolicyButton()">
              <label class="radio-label" for="radio-policy">{{'SUBSCRIBE.THE' | translate}}
                <a class="link" routerLink="/policy" href="">{{'SUBSCRIBE.POLICY' | translate}}</a>
                {{'SUBSCRIBE.UNDERSTOOD_AND_ACCEPTED' | translate}}
              </label>
            </section>

            <section class="radio-wrapper">
              <input type="radio" id="radio-terms" formControlName="termsChecked" (click)="toggleTermsButton()">
              <label class="radio-label" for="radio-terms">{{'SUBSCRIBE.ACCEPT' | translate}}
                <a class="link" routerLink="/terms" href="">{{'SUBSCRIBE.ASZF' |translate}}</a>
              </label>
            </section>

            <section class="radio-wrapper">
              <input type="radio" id="sub" formControlName="isSubbed" value=true (click)="toggleSubButton()">
              <label class="radio-label"
                     for="sub">{{'PAYMENT.SUB_TO_MAIL'| translate}}</label>
            </section>
          </section>

          <section class="button-container container">
            <section class="row justify-content-between">
              <button class="button" (click)="scrollTop()">{{'BUTTON.BACK' | translate}}</button>
              <button class="button" (click)="alertHandler(); scrollTop()">{{'BUTTON.CONTINUE' | translate}}</button>
            </section>
          </section>
        </section>
      </section>
    </form>
    <section class="col-lg-3 col-xl-4"></section>
  </section>

  <section *ngIf="routeState === 'shipping-and-payment'" class="number-02-container container">
    <form [formGroup]="shippingForm">
      <section class="row">
        <section class="col-lg-7">
          <section class="shipping-section">
            <p class="title-medium">{{'PAYMENT.SHIPPING_TYPE' | translate}}</p>

            <section>
              <section class="container">
                <section class="shipping-method row justify-content-between">
                  <img class="shipping-logo col-4" src="assets/images/GLS.png">
                  <p class="shipping-name col-5">{{gls_default && gls_default.title}}</p>
                  <p class="shipping-price col-2">{{gls_default && gls_default.quote.weight_5.text}}</p>
                  <input class="shipping-radio col-1" type="radio" id="shipping1" formControlName="shipping1" value=true
                         checked
                         disabled (click)="toggleShippingButton()">
                </section>
              </section>
            </section>
          </section>

          <section class="payment-section">
            <p class="title-medium">{{'PAYMENT.PAYMENT_TYPE' | translate}}</p>
            <section>
              <section class="container">
                <section class="shipping-method row justify-content-between">
                  <a class="simple-pay-img-wrapper" target="_blank"
                     href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"><img
                    class="shipping-logo simple-pay-logo col-4" src="assets/images/simplepay.png"></a>
                  <p class="shipping-name col-5">{{simple_pay && prettierPaymentType(simple_pay.title, 0)}}<span
                    class="shipping-name-bracket"> (Mastercard, Maestro, Visa, Visa Electron, American Express)</span>
                  </p>
                  <input class="shipping-radio col-1" type="radio" id="simplepay" (click)="toggleSimplePay()"
                         value="simplepay" formControlName="simplepay">

                  <section *ngIf="isSimplePayClicked">
                    <input type="checkbox" id="simplepay-policy" [checked]="isSimplePolicyChecked"
                           (change)="isSimplePolicyChecked = !isSimplePolicyChecked">

                    {{'PAYMENT.SIMPLE_POLICY_1' | translate}}
                    <a href="https://simplepay.hu/vasarlo-aff/" target="_blank">https://simplepay.hu/vasarlo-aff/</a>
                  </section>
                </section>

                <section class="shipping-method row justify-content-between">
                  <!--                <img class="shipping-logo col-4" src="assets/images/GLS.png">-->
                  <p
                    class="shipping-name cod-name shipping-logo col-4">{{gls_cash_delivery && gls_cash_delivery.name}}</p>
                  <!--<p class="shipping-price col-5">{{gls_cash_delivery && gls_cash_delivery.quote.gls_cod5.text}}</p>-->
                  <p class="shipping-price col-5">+ 390 Ft</p>
                  <input class="shipping-radio col-1" type="radio" id="cod" value="cod" formControlName="cod"
                         (click)="toggleCOD()">
                </section>
              </section>
            </section>
          </section>

          <section class="button-container container">
            <section class="row justify-content-between">
              <button class="button" routerLink="/payment/personal-data"
                      (click)="scrollTop()">{{'BUTTON.BACK' | translate}}</button>
              <button *ngIf="!isSimplePayClicked || isSimplePolicyChecked && isSimplePayClicked " class="button"
                      (click)="setShippingAndPayment(); scrollTop()">{{'BUTTON.CONTINUE' | translate}}</button>
              <button *ngIf="isSimplePayClicked && !isSimplePolicyChecked" class="button"
                      (click)="popUpWarning()">{{'BUTTON.CONTINUE' | translate}}</button>
            </section>
          </section>
        </section>
      </section>
    </form>
  </section>

  <section *ngIf="routeState === 'summary'" class="summary-container container">
    <section class="row">
      <section class="col-lg-7">
        <section class="costumer-data-container container">
          <section class="data-header row justify-content-between">
            <p class="summary-title col-4">{{'PAYMENT.COSTUMER_DATA' | translate}}</p>
            <section class="col-2">
              <img class="edit-img" src="assets/images/edit_icon.svg" routerLink="/payment/personal-data">
            </section>
          </section>

          <section class="customer-data-header row">
            <div class="col-12 col-sm-4">
              <p class="data-span">{{'PAYMENT.NAME' | translate}}</p>
              <p class="data-text data-name-text">{{costumerName}}</p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="data-span">{{'PAYMENT.E-MAIL' | translate}}</p>
              <p class="data-text">{{costumerEmail}}</p>
            </div>

            <div class="col-12 col-sm-4">
              <p class="data-span">{{'PAYMENT.PHONENUMBER' | translate}}</p>
              <p class="data-text">{{costumerPhone}}</p>
            </div>
          </section>
        </section>

        <section class="shipping-data-container container">
          <section class="data-header row justify-content-between">
            <p class="summary-title col-6">{{'PAYMENT.SHIPPING_ADDRESS' | translate}}</p>
            <section class="col-2">
              <img class="edit-img" src="assets/images/edit_icon.svg" routerLink="/payment/personal-data">
            </section>
          </section>

          <section class="customer-data-header row">
            <p class="data-span col-6">{{'PAYMENT.ADDRESSS' | translate}}</p>
            <p class="data-span col-6">{{'PAYMENT.OTHERR' | translate}}</p>
            <p class="data-text col-6">{{costumerAddress}}</p>
            <p class="data-text col-6">{{costumerOther}}</p>
            <p class="data-span col-12">{{'PAYMENT.NOTE_TO_THE_COURIER' | translate}}</p>
            <p class="data-text col-12">{{costumerComment}}</p>
          </section>
        </section>

        <section *ngIf="coupon" class="shipping-data-container container">
          <section class="data-header row justify-content-between">
            <p class="summary-title col-6">{{'PAYMENT.DISCOUNT' | translate}}</p>
            <section class="col-2">
              <img class="edit-img" src="assets/images/close_x.svg" (click)="removeCoupon()">
            </section>
          </section>

          <section class="customer-data-header row">
            <p class="data-text col-12">{{couponOff}}</p>
          </section>
        </section>

        <section class="shipping-and-payment-container row justify-content-between">
          <section class="shipping-container col-5">
            <section class="data-header row justify-content-between">
              <p class="summary-title col-8">{{'PAYMENT.TYPE_OF_SHIPPING' | translate}}</p>
              <section class="col-4">
                <img class="edit-img" src="assets/images/edit_icon.svg" routerLink="/payment/shipping-and-payment">
              </section>
            </section>
            <section class="selected-shipping row">
              <p>{{costumerShippingMethod}}</p>
            </section>
          </section>

          <section class="payment-container col-5">
            <section class="data-header row justify-content-between">
              <p class="summary-title col-8">{{'PAYMENT.TYPE_OF_PAYMENT' | translate}}</p>
              <section class="col-4">
                <img class="edit-img" src="assets/images/edit_icon.svg" routerLink="/payment/shipping-and-payment">

              </section>
            </section>
            <section class="selected-payment row">
              <a class="simple-pay-img-wrapper col-4" target="_blank"
                 href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"><img
                *ngIf="checkPaymentMethod()" class="shipping-logo simple-pay-logo"
                src="assets/images/simplepay.png"></a>
              <p>{{showCod(costumerPaymentMethod)}}</p>
            </section>
          </section>
        </section>

        <section class="button-container container">
          <section class="row justify-content-between">
            <button class="button" routerLink="/payment/shipping-and-payment"
                    (click)="scrollTop()">{{'BUTTON.BACK' | translate}}</button>
            <button class="button" (click)="pay()">{{'BUTTON.PAY' | translate}}</button>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>

