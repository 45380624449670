import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {catchError} from 'rxjs/operators';
import {PersistanceService} from './persistance.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  httpOptions: object;
  routeAfterLogin = '';
  cookieWarning = false;
  cookiesRejected = false;
  cookiesRejectedOnce = false;
  goPayment = false;
  goPaymentAfterLogin = false;
  showBasket = false;
  itemsInBasket = 0;

  lang = [
    {code: 'hu-hu'},
    {code: 'en-gb'},
  ];


  private loadHeader(): void {
    if (this.persistance.get('cookies_accepted')) {
      this.cookiesRejected = false;
    }

    if (this.persistance.get('lang')) {
    } else {
      this.persistance.set('lang', 'hu-hu');
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Accept-Language': this.persistance.get('lang'),
      })
    };
  }

  private loadLangHeader(lang): void {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Accept-Language': lang,
      })
    };
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private persistance: PersistanceService,
    private translate: TranslateService
  ) {
  }

  // Get page

  getPage(page): Observable<any> {
    this.loadHeader();

    return this.http.get<any>(environment.apiUrl + '/' + page, this.httpOptions);
  }

  // Blog requests

  getBlog(slug): Observable<any> {
    this.loadHeader();
    return this.http.get<any>(environment.apiUrl + '/blog/show&_route_=' + slug, this.httpOptions);
  }

  // Payment & Shipping
  /*localisation/zone&country_id=76&api_token=[apiToken]*/

  getZones(): Observable<any> {
    /*ToDo: remove hardcode when the market gets global */
    const countryId = 97;

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      return this.http.post<any>(environment.apiUrl + '/localisation/zone&country_id=' + countryId + '&api_token=' + this.persistance.get('api_token'), this.httpOptions);
    }
  }

  setShippingAddress(firstname, lastname, email, address_1, address_2, city, country_id): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('firstname', firstname);
    body.append('lastname', lastname);
    body.append('email', email);
    body.append('address_1', address_1);
    body.append('address_2', address_2);
    body.append('city', city);
    body.append('country_id', country_id);
    body.append('zone_id', '0');

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/shipping/address' + '&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  setBilling(firstname, lastname, email, address_1, address_2, city, country_id): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('firstname', firstname);
    body.append('lastname', lastname);
    body.append('email', email);
    body.append('address_1', address_1);
    body.append('address_2', address_2);
    body.append('city', city);
    body.append('country_id', country_id);
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/payment/address' + '&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  getShippingMethods(): Observable<any> {
    this.loadHeader();
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      return this.http.get<any>(environment.apiUrl + '/shipping' + '&api_token=' + this.persistance.get('api_token'), this.httpOptions);
    }
  }

  getPaymentMethods(): Observable<any> {
    this.loadHeader();
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      return this.http.get<any>(environment.apiUrl + '/payment' + '&api_token=' + this.persistance.get('api_token'), this.httpOptions);
    }
  }

  setShippingMethod(shipping_method): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('shipping_method', shipping_method);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/shipping/method' + '&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  setPaymentMethod(payment_method): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('payment_method', payment_method);
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/payment/method' + '&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  setCustomer(firstname, lastname, email, telephone): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('firstname', firstname);
    body.append('lastname', lastname);
    body.append('email', email);
    body.append('telephone', telephone);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/customer' + '&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  sendOrder(comment): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('comment', comment);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/order/add' + '&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  // tslint:disable-next-line:typedef
  async getOrder(orderId) {
    this.loadHeader();
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {

      // tslint:disable-next-line:max-line-length
      return await this.http.get<any>(environment.apiUrl + '/order/info' + '&api_token=' + this.persistance.get('api_token') + '&order_id=' + orderId, this.httpOptions).toPromise();
    }
  }

// ToDo: remove hardcode later
  getBlogs(): Observable<any> {
    this.loadHeader();
    return this.http.get<any>(environment.apiUrl + '/blog&limit=99&offset=0', this.httpOptions);
  }

  getBlogsWithLimitAndOffset(limit, offset): Observable<any> {
    this.loadHeader();
    return this.http.get<any>(environment.apiUrl + '/blog&limit=' + limit + '&offset=' + offset, this.httpOptions);
  }

// User requests

  guestToken(): Observable<any> {
    this.loadHeader();

    const body = new FormData();

    body.append('key', environment.apiKey);
    body.append('guest-login', '1');

    return this.http.post<any>(environment.apiUrl + '/login', body, this.httpOptions);
  }

  guestLogin(email, password): Observable<any> {
    this.loadHeader();

    const body = new FormData();

    body.append('email', email);
    body.append('password', password);
    body.append('key', environment.apiKey);

    if (this.persistance.get('wishlist')) {
      JSON.parse(this.persistance.get('wishlist')).forEach((item, index) => {
        body.append('wishlist[' + index + ']', item);
      });
    }

    return this.http.post<any>(environment.apiUrl + '/login&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
  }

  register(firstname, lastname, email, password, confirm, terms, privacy): Observable<any> {
    this.loadHeader();

    const body = new FormData();

    body.append('firstname', firstname);
    body.append('lastname', lastname);
    body.append('email', email);
    body.append('password', password);
    body.append('confirm', confirm);
    body.append('terms', terms);
    body.append('privacy', privacy);
    body.append('key', environment.apiKey);
    body.append('customer_group_id', '3');

    return this.http.post<any>(environment.apiUrl + '/customer/register', body, this.httpOptions);
  }

  forgotPswd(email): Observable<any> {
    this.loadHeader();

    const body = new FormData();

    body.append('email', email);

    return this.http.post<any>(environment.apiUrl + '/customer/forgotten', body, this.httpOptions);
  }

  setNewPassword(password, cpassword, code): Observable<any> {
    this.loadHeader();

    const body = new FormData();

    body.append('password', password);
    body.append('confirm', cpassword);
    body.append('code', code);

    return this.http.post<any>(environment.apiUrl + '/customer/reset', body, this.httpOptions);
  }


  // Subscribe

  subscribeMail(email, terms, privacy, firstname): Observable<any> {
    this.loadHeader();
    this.loadLangHeader(this.persistance.get('def_lang'));

    const body = new FormData();

    body.append('email', email);
    body.append('terms', terms);
    body.append('privacy', privacy);
    body.append('firstname', firstname);
    body.append('key', environment.apiKey);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      return this.http.post<any>(environment.apiUrl + '/customer/subscribe', body, this.httpOptions);
    }
  }

  unsubscribeMail(email, code): Observable<any> {
    const body = {
      email,
      code
    };
    return this.http.post<any>(environment.apiUrl + '/customer/unsubscribe', body, this.httpOptions);
  }

  // Cart

  addCart(productId, option, optionValueId): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('product_id', productId);
    if (option === 0 && optionValueId === 0) {
      body.append('option[' + option + ']', optionValueId);
    }
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      this.itemsInBasket = this.itemsInBasket + 1;
      return this.http.post<any>(environment.apiUrl + '/cart/add&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  editCart(cartId, quantity): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('key', cartId);
    body.append('quantity', quantity);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      return this.http.post<any>(environment.apiUrl + '/cart/edit&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  removeCart(cartId): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('key', cartId);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/cart/remove&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  getCart(): Observable<any> {
    this.loadHeader();
    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      return this.http.get<any>(environment.apiUrl + '/cart/products&api_token=' + this.persistance.get('api_token'), this.httpOptions);
    }
  }

  // About us page

  getAboutUsPage(): Observable<any> {
    this.loadHeader();
    return this.http.get<any>(environment.apiUrl + '/page/show&type=about-us', this.httpOptions);
  }

  // Products

  getCategory(): Observable<any> {
    this.loadHeader();
    if (this.persistance.get('api_token')) {
      return this.http.get<any>(environment.apiUrl + '/category&api_token=' + this.persistance.get('api_token'), this.httpOptions);
    } else {
      return this.http.get<any>(environment.apiUrl + '/category', this.httpOptions);
    }
  }

  filterProducts(filterCategories, filterPrices, filterPromotion): Observable<any> {
    this.loadHeader();

    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(environment.apiUrl + '/product/filter&' + filterCategories + filterPrices + filterPromotion, this.httpOptions);
  }

  showProduct(slug): Observable<any> {
    this.loadHeader();

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      if (this.persistance.get('logged_in_user')) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<any>(environment.apiUrl + '/product/show&_route_=' + slug + '&api_token=' + this.persistance.get('api_token') + '&width=656&height=860', this.httpOptions);
      } else {
        return this.http.get<any>(environment.apiUrl + '/product/show&_route_=' + slug + '&width=656&height=860', this.httpOptions);
      }
    }
  }

  showRelatedProducts(slug): Observable<any> {
    this.loadHeader();
    return this.http.get<any>(environment.apiUrl + '/product/show&_route_=' + slug + '&width=489&height=540', this.httpOptions);
  }

  // Wishlist

  addToWishlist(productId): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('product_id', productId);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/wishlist/add&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  removeFromWishlist(productId): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('product_id', productId);

    if (this.cookiesRejected) {
      this.cookieWarning = true;
    } else if (!this.cookieWarning) {
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(environment.apiUrl + '/wishlist/remove&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
    }
  }

  // Profile

  getAccountData(): Observable<any> {
    this.loadHeader();
    return this.http.get<any>(environment.apiUrl + '/account&api_token=' + this.persistance.get('api_token'), this.httpOptions);
  }

  // tslint:disable-next-line:max-line-length
  setAccountData(firstname, lastname, address1, address2, city, company, postcode, phone, addressFirstName, addressLastName): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('address[zone_id]', '4239');
    body.append('address[country_id]', '97');

    body.append('account[firstname]', firstname);
    body.append('account[lastname]', lastname);
    body.append('account[telephone]', phone);

    body.append('address[firstname]', addressFirstName);
    body.append('address[lastname]', addressLastName);
    body.append('address[address_1]', address1);
    body.append('address[address_2]', address2);
    body.append('address[city]', city);
    body.append('address[company]', company);
    body.append('address[postcode]', postcode);

    // tslint:disable-next-line:max-line-length
    return this.http.post<any>(environment.apiUrl + '/account/address&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
  }

  // Coupons

  applyCoupon(coupon): Observable<any> {
    this.loadHeader();

    const body = new FormData();
    body.append('coupon', coupon);

    return this.http.post<any>(environment.apiUrl + '/cart/coupon&api_token=' + this.persistance.get('api_token'), body, this.httpOptions);
  }

  deleteCoupon(): Observable<any> {
    this.loadHeader();
    return this.http.post<any>(environment.apiUrl + '/cart/couponDelete&api_token=' + this.persistance.get('api_token'), this.httpOptions);
  }

  // Refresh token

  refreshToken(loggedInUser): void {
    this.routeAfterLogin = this.router.url;
    if (loggedInUser) {
      this.persistance.set('logged_in_user', false);
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
        }
      });
      anjaAlert.fire({
        title: this.translate.instant('LOGIN.PLEASE_LOGIN'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
        cancelButtonText: this.translate.instant('LOGIN.NOT_YET')
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/login']);
        } else {
          this.persistance.remove('logged_in_user');
          this.guestToken().subscribe(response => {
            this.persistance.set('api_token', response.data.api_token);
          });
        }
      });
    } else {
      this.guestToken().subscribe(response => {
        this.persistance.set('api_token', response.data.api_token);
      });
    }
  }

  refreshTokenThenAddProduct(loggedInUser, productId, optionId, valueId): void {
    this.routeAfterLogin = this.router.url;
    if (loggedInUser) {
      this.persistance.set('logged_in_user', false);
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
        }
      });
      anjaAlert.fire({
        title: this.translate.instant('LOGIN.PLEASE_LOGIN'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
        cancelButtonText: this.translate.instant('LOGIN.NOT_YET')
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/login']);
        } else {
          this.persistance.remove('logged_in_user');
          this.guestToken().subscribe(response => {
            this.persistance.set('api_token', response.data.api_token);
          });
        }
      });
    } else {
      this.guestToken().subscribe(response => {
        this.persistance.set('api_token', response.data.api_token);
        this.addCart(productId, optionId, valueId).subscribe(() => {
          this.getItemsInBasket();
        });
      });
    }

  }

  getItemsInBasket(): void {
    this.getCart().subscribe(response => {

      if (response.data.products.length === 0) {
        this.itemsInBasket = 0;
        return;
      }

      response.data.products.forEach(product => {
        this.itemsInBasket = this.itemsInBasket = product.quantity;
      });

    });
  }

}
