import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {CategoryComponent} from '../category/category.component';
import {PersistanceService} from '../persistance.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  cookieText: any;
  menuItems = [];
  topBarData: any;
  navbarRefreshBasket = false;
  cookieAccepted: boolean;
  currentLang = '';
  showLangs = false;
  langs = [
    {lang: 'HUN', code: 'hu-hu'},
    {lang: 'ENG', code: 'en-gb'},
  ];

  constructor(
    public service: AppService,
    public router: Router,
    private category: CategoryComponent,
    private persistance: PersistanceService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getLang();

    // this.currentLang
    if (!this.persistance.get('cookies_accepted')) {
      this.cookieAccepted = false;
    } else {
      this.cookieAccepted = true;
      this.service.cookiesRejected = false;
      this.service.cookiesRejectedOnce = false;
      this.service.cookieWarning = false;
    }

    this.service.getItemsInBasket();

    if (!this.service.cookiesRejected) {
      if (!this.persistance.get('api_token') || this.persistance.get('api_token') === 'undefined') {
        this.service.guestToken().subscribe(response => {
          this.persistance.set('api_token', response.data.api_token);
        });
      }
    }

    this.navbarRefreshBasket = true;
    this.service.getCategory().subscribe(response => {
      Object.values(response.data.categories).forEach(category => {
        // @ts-ignore
        this.menuItems.push((category));
      });
    });

    this.service.getPage('homepage').subscribe(homepageData => {
      this.topBarData = homepageData.data['top-notification'];
    });
  }

  setCategoryData(category): void {
    const products = [];
    this.category.title = category.name;
    this.category.description = category.description;
    Object.values(category.products).forEach(product => {
      products.push(product);
    });
    this.category.products = products;
  }

  setCookie(value): void {
    if (value) {
      this.persistance.set('cookies_accepted', true);
      this.cookieAccepted = true;
      location.reload();
    } else {
      this.service.cookiesRejectedOnce = true;
      this.service.cookiesRejected = true;
    }
  }

  setCookieAfterWarning(): void {
    this.router.navigate(['/']);
    this.persistance.set('cookies_accepted', true);
    this.cookieAccepted = true;
    this.service.cookiesRejected = false;
    this.service.cookiesRejectedOnce = false;
  }

  // tslint:disable-next-line:typedef
  setLang(code: string, lang: string) {
    this.translate.use(code);
    this.showLangs = false;
    this.currentLang = lang;
    this.persistance.set('lang', code);
    location.reload();
  }

  // tslint:disable-next-line:typedef
  getLang() {
    if (this.persistance.get('lang')) {
      this.translate.use(this.persistance.get('lang'));
    } else {
      this.translate.use('hu-hu');
    }

    this.translate.get('LANG.' + this.translate.currentLang).subscribe(translate => {
      this.currentLang = translate;
    });
  }
}
