<section class="container-wrapper">
  <div class="logo-wrapper">
    <img routerLink="/" class="logo" src="assets/images/top-logo.svg">
  </div>

  <section *ngIf="lang === 'en-gb'" class="container">
    <div class="calibre" id="calibre_link-0">
      <h1 class="block_" id="calibre_link-1">1. INTRODUCTION</h1>
      <p class="block_1"><span class="text_">In this Privacy Policy (hereinafter: </span><span
        class="text_1">Policy</span><span class="text_">), </span><span class="text_1">Herman Pharma Kft.</span><span
        class="text_"> (registered office: H-2363 Felsőpakony, utca, top. lot number 806, tax number:</span><span
        class="text_2"> </span><span class="text_">24689258-2-13, company registration number: 13 09 165671, hereinafter: </span><span
        class="text_1">Controller</span><span class="text_">) describes how it collects, uses, discloses, transfers, and stores the data of visitors to the </span><a
        href="http://www.anja.hu" class="text_3">www.anja.hu</a><span class="text_"> website operated by it. </span></p>
      <p class="block_1">&nbsp;</p>
      <p class="block_2">The Controller represents that this Privacy Policy complies with applicable data protection
        rules.</p>
      <p class="block_2"><span class="text_4">The Controller is entitled to unilaterally amend this Privacy Policy at any time in order to comply with effective legal provisions, including amendments related to changes in the services provided by the Controller. We inform Data Subjects of any amendments to this Privacy Policy concurrently with such changes, through our website at </span><a
        href="http://www.anja.hu" class="text_5">www.anja.hu</a><span class="text_6">.</span><span
        class="text_4"> </span></p>
      <p class="block_1"><span
        class="text_">Should you have any questions concerning this Policy, please contact us at </span><a
        href="mailto:info@anja.hu" class="text_7">info@anja.hu</a><span class="text_2"> </span><span class="text_">and our Data Protection Officer will answer any questions you may have. This Privacy Policy and any amendments thereto are available on the Company's website at </span><a
        href="http://www.anja.hu/adatv%C3%A9delem" class="text_8">www.anja.hu/adatvédelem</a><span
        class="text_">. </span></p>
      <p class="block_1">&nbsp;</p>
      <p class="block_2">In drafting this Policy, the Controller took the following laws and legal regulations into
        account:</p>
      <p class="block_1">&nbsp;</p>
      <ul class="list_">
        <li class="block_3">Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on
          the protection of natural persons with regard to the processing of personal data and on the free movement of
          such data, and repealing Directive 95/46/EC (<b class="calibre1">GDPR</b>),
        </li>
        <li class="block_3">Act CXII of 2011 on Informational Self-Determination and Freedom of Information (<b
          class="calibre1">Privacy Act</b>),
        </li>
        <li class="block_3">Act V of 2013 on the Civil Code (<b class="calibre1">Civil Code</b>),</li>
        <li class="block_3">Act XLVIII of 2008 on the Essential Conditions of and Certain Limitations on Business
          Advertising (<b class="calibre1">Advertising Act</b>),
        </li>
        <li class="block_3">Act CVIII of 2001 on Certain Issues of Electronic Commerce Services and Information
          Society-Related Services (<b class="calibre1">E-commerce Act</b>),
        </li>
        <li class="block_3">Act CXIX of 1995 on the Use of Name and Address Information Serving the Purposes of Research
          and Direct Marketing (<b class="calibre1">Direct Marketing Act</b>),
        </li>
        <li class="block_3">Act C of 2000 on Accounting (<b class="calibre1">Accounting Act</b>),</li>
        <li class="block_3">Act CL of 2017 on the Rules of Taxation (<b class="calibre1">Act on Taxation</b>),</li>
        <li class="block_3">Act CLV of 1997 on Consumer Protection (<b class="calibre1">Consumer Protection Act</b>),
        </li>
        <li class="block_3">Act CLIX of 2012 on Postal Services (<b class="calibre1">Postal Services Act</b>),</li>
        <li class="block_3">Act CCXXXVII of 2013 on Credit Institutions and Financial Enterprises (<b class="calibre1">Credit
          Institutions Act</b>),
        </li>
        <li class="block_3">Decree No. 19/2014 (IV. 29.) of the MNE on the procedural rules for handling warranty and
          guarantee claims for goods sold under a contract between a consumer and a business (<b class="calibre1">MNE
            Decree on Consumer Protection</b>),
        </li>
      </ul>
      <p class="block_4">&nbsp;</p>
      <h1 class="block_" id="calibre_link-2">2. DEFINITIONS</h1>
      <p class="block_2">The following terms used herein have the following meaning:</p>
      <p class="block_2">“<b class="calibre1">processor</b>”: means a natural or legal person, public authority, agency
        or other body which processes personal data on behalf of the controller;</p>
      <p class="block_2">&nbsp;“<b class="calibre1">data processing</b>”: means any operation or set of operations which
        is performed on personal data or on sets of personal data, whether or not by automated means, such as
        collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation,
        use, disclosure by transmission, dissemination or otherwise making available, alignment or combination,
        restriction, erasure or destruction;</p>
      <p class="block_2">“<b class="calibre1">restriction of processing</b>“:<b class="calibre1"> </b>means the marking
        of stored personal data with the aim of limiting their processing in the future;</p>
      <p class="block_2">“<b class="calibre1">controller</b>“:<b class="calibre1"> </b>means the natural or legal
        person, public authority, agency or other body which, alone or jointly with others, determines the purposes and
        means of the processing of personal data; where the purposes and means of such processing are determined by
        Union or domestic law, the controller or the specific criteria for its nomination may be provided for by Union
        or domestic law;</p>
      <p class="block_2"><b class="calibre1">“personal data breach</b>“: means a breach of security leading to the
        accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data
        transmitted, stored or otherwise processed;</p>
      <p class="block_2"> “<b class="calibre1">recipient</b>”:<b class="calibre1"> </b>means a natural or legal person,
        public authority, agency or another body, to which the personal data are disclosed, whether a third party or
        not. However, public authorities which may receive personal data in the framework of a particular inquiry in
        accordance with Union or domestic law&nbsp;shall not be regarded as recipients; the processing of those data by
        those public authorities shall be in compliance with the applicable data protection rules according to the
        purposes of the processing;</p>
      <p class="block_2">"<b class="calibre1">cookie</b>": means a short text file that our web server sends to the data
        subject’s device (be it any computer, mobile phone or tablet) and reads back. There are temporary (session)
        cookies, which are automatically deleted from the device when the browser is close, and longer-term cookies,
        which remain on the device for a longer period of time (depending on the settings of the data subject’s device);
      </p>
      <p class="block_2">“<b class="calibre1">data subject</b>“: means any person identified or identifiable, directly
        or indirectly, by reference to the personal data, who must always be a specific person. Only natural persons are
        considered data subjects, not legal entities, and as such data protection only protects the data of natural
        persons. However, personal data also includes, for example, the data of an sole proprietor or a representative
        of a company (e.g. telephone number, email address, place and date of birth, etc.).</p>
      <p class="block_2"><b class="calibre1">“data subject consent</b>“: means any freely given, specific, informed and
        unambiguous indication of the data subject's wishes by which they, by a statement or by a clear affirmative
        action, signifies agreement to the processing of personal data relating to them;</p>
      <p class="block_2">“<b class="calibre1">third party</b>“: means a natural or legal person, public authority,
        agency or body other than the data subject, controller, processor and persons who, under the direct authority of
        the controller or processor, are authorised to process personal data;</p>
      <p class="block_2">"<b class="calibre1">third country</b>": means a country that is not a member of the European
        Union or the European Economic Area. Member States of the European Union may conclude international agreements
        which involve the transfer of personal data to third countries or international organisations, as far as such
        agreements do not affect the GDPR or any other provisions of Union law;</p>
      <p class="block_2">“<b class="calibre1">binding corporate rules</b>“: mean personal data protection policies which
        are adhered to by a controller or processor established on the territory of a Member State for transfers or a
        set of transfers of personal data to a controller or processor in one or more third countries within a group of
        undertakings, or group of enterprises engaged in a joint economic activity;</p>
      <p class="block_2"> “<b class="calibre1">personal data</b>“: means any information relating to an identified or
        identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified,
        directly or indirectly, in particular by reference to an identifier such as a name, an identification number,
        location data, an online identifier or to one or more factors specific to the physical, physiological, genetic,
        mental, economic, cultural or social identity of that natural person. Natural persons may be associated with
        online identifiers provided by their devices, applications, tools, and protocols, such as internet protocol
        addresses, cookie identifiers or other identifiers such as radio frequency identification tags. This may leave
        traces which, in particular when combined with unique identifiers and other information received by the servers,
        may be used to create profiles of the natural persons and identify them.</p>
      <p class="block_2">“<b class="calibre1">international organisation</b>“: means an organisation and its subordinate
        bodies governed by public international law, or any other body which is set up by, or on the basis of, an
        agreement between two or more countries;</p>
      <p class="block_2">“<b class="calibre1">filing system</b>“:<b class="calibre1"> </b>means any structured set of
        personal data which are accessible according to specific criteria, whether centralised, decentralised or
        dispersed on a functional or geographical basis;</p>
      <p class="block_2">“<b class="calibre1">enterprise</b>“: means a natural or legal person engaged in an economic
        activity, irrespective of its legal form, including partnerships or associations regularly engaged in an
        economic activity;</p>
      <p class="block_1">&nbsp;</p>
      <h1 class="block_" id="calibre_link-3">3. DESCRIPTION OF THE PRINCIPLES GOVERNING PROCESSING</h1>
      <p class="block_2">The Controller processes personal data lawfully and fairly, in a manner that is transparent for
        data subjects, for the explicit and legitimate purposes set out in this Policy and in the documents annexed
        hereto ("<b class="calibre1">purpose limitation</b>"). Processing is limited to the extent necessary for
        achieving the objectives of the Controller ("<b class="calibre1">data minimisation</b>"). In accordance with the
        principle of accuracy, the Controller shall ensure that the personal data it processes are kept up to date, and
        to this end the Controller shall take all reasonable steps to delete or rectify without delay any personal data
        that are inaccurate for the purposes of the processing ("<b class="calibre1">accuracy</b>"). The Controller
        acknowledges that it may store personal data only for the time necessary for achieving its objectives ("<b
          class="calibre1">storage limitation</b>"). The Controller processes data in a manner that ensures appropriate
        security of the personal data, including protection against unauthorised or unlawful processing and against
        accidental loss, destruction, or damage, using appropriate technical or organisational measures (“<b
          class="calibre1">integrity and confidentiality</b>”). The Controller keeps internal records of its processing
        operations to demonstrate compliance with the principles set out above ("<b class="calibre1">accountability</b>").
      </p>
      <p class="block_2">The principles in this Policy describe our practices regarding personal data. Our processing
        principles apply to paper-based processing and to all devices, tools, websites, customer service platforms or
        other online applications operated by the Controller that refer to them by means of a hyperlink or other
        means. </p>
      <p class="block_1">&nbsp;</p>
      <h1 class="block_" id="calibre_link-4">4. GENERAL INFORMATION ON PROCESSING</h1>
      <p class="block_2">The Controller processes the personal data of data subjects in order to provide the services
        used by data subjects and to improve user experience. This Policy provides information on the processing of data
        by the Controller in relation to the activities listed below: </p>
      <p class="block_1">&nbsp;</p>
      <p class="block_5"><span class="text_4">a) as part of purchases made on the </span><b class="calibre1">anja.hu</b><span
        class="text_4">&nbsp;website and the services provided on the website,</span></p>
      <p class="block_6">b) as part of subscribing to newsletters,</p>
      <p class="block_6">c) as part of complaints handling,</p>
      <p class="block_6">d) in relation to responding to enquiries made to the company through various communication
        channels,</p>
      <p class="block_6">e) in connection with the processing of data via cookies on websites operated by the
        company. </p>
      <p class="block_1">&nbsp;</p>
      <h2 class="block_7" id="calibre_link-5">4.1. General information on the various data processing operations
        specified above:</h2>
      <p class="block_8"></p>
      <div class="calibre2">
        <div class="block_9"><span class="bullet_">a)&nbsp;</span><span class="calibre3"><b class="calibre1"><span
          class="calibre4">For the purpose of this Privacy and Data Processing Policy, the following qualify as controllers:  </span></b></span>
        </div>
      </div>
      <p id="calibre_link-6" class="block_10">Herman Pharma Kft.</p>
      <p class="block_10">Registered office: H-2363 Felsőpakony, utca, top. lot number 806 </p>
      <p class="block_10">Tax number: 24689258-2-13</p>
      <p class="block_10">Company registration number: 13 09 165671<br class="calibre5"/>&nbsp;</p>
      <div class="calibre2">
        <div class="block_9"><span class="bullet_">a)&nbsp;</span><span class="calibre3"><b class="calibre1">Data Protection Officer, data protection-related liaising: </b></span>
        </div>
      </div>
      <p class="block_10">Beáta Kaszó</p>
      <p class="block_11"><span class="text_9">Email: </span><a href="mailto:beata.kaszo@anja.hu" class="text_10">beata.kaszo@anja.hu</a>
      </p>
      <p class="block_10">Telephone: +36 30475 24 58</p>
      <p class="block_2">c) <b class="calibre1">purpose and legal basis for the processing of personal data: </b>specified
        for the various processing operations below and in the relevant annex;</p>
      <p class="block_2">d) <b class="calibre1">the duration of the storage of personal data, or criteria used to
        determine such duration:</b> specified for the various processing operations;</p>
      <p class="block_2">e) <b class="calibre1">data subjects</b> have the right, pursuant to Section 9 of this Policy,
        to request from the Controller access to, rectification, erasure or restriction of processing of personal data
        concerning them, and to object to the processing of such personal data, as well as to exercise their right to
        data portability.</p>
      <p class="block_2">The Controller has carried out in advance and applies the interest balancing test in all
        necessary cases, taking into account the requirements of necessity and proportionality, the principle of
        gradualism and the requirement of preliminary information.</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_1">&nbsp;</p>
      <h1 class="block_" id="calibre_link-7">5. INFORMATION ON THE VARIOUS PROCESSING OPERATIONS</h1>
      <p class="block_12">&nbsp;</p>
      <p id="calibre_link-8" class="block_2">&nbsp;</p>
      <div class="calibre6">
        <div class="block_13" id="calibre_link-9"><span class="bullet_1">.1.&nbsp;</span><span class="calibre7">Registration and purchases on the anja.hu website</span>
        </div>
      </div>
      <p class="block_14">&nbsp;</p>
      <p class="block_15"><b class="calibre1">Purpose of processing: </b></p>
      <div class="calibre8">
        <div class="block_16"><span class="bullet_">-&nbsp;</span><span class="calibre3">Sale of products online, documentation of purchase and payment, fulfilment of accounting obligations,</span>
        </div>
        <div class="block_16"><span class="bullet_">-&nbsp;</span><span class="calibre3">identification and contacting of the user as a customer, and liaising,</span>
        </div>
        <div class="block_16"><span class="bullet_">-&nbsp;</span><span class="calibre3"> fulfilment of the product (or service) ordered,</span>
        </div>
        <div class="block_16"><span class="bullet_">-&nbsp;</span><span class="calibre3"> invoice issuing, the possibility of making payments,</span>
        </div>
      </div>
      <p class="block_17">&nbsp;</p>
      <p class="block_18"><b class="calibre1">Scope of data subjects: </b>Customers who purchase a product or service
        from the company's online store. </p>
      <p class="block_19">Scope of data processed: </p>
      <p class="block_20">Basic data: email address, telephone number, first and last name</p>
      <p class="block_20">Billing address(es): first name, last name, telephone number, address (country, postal code,
        settlement/town, street, house number)</p>
      <p class="block_20">Delivery address(es): required: (first name, last name, telephone number, address (country,
        postal code, settlement/town, street, house number)</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_19">Legal basis for processing</p>
      <p class="block_20">In the case of the online sale of products, the data subject's consent pursuant to Article
        6(1)(a) of the GDPR and the performance of a contract pursuant to Article 6(1)(b) of the GDPR.</p>
      <p class="block_20">In the case of the documentation of purchase and payment, the fulfilment of the accounting
        obligation and invoice issuing and the making payments, compliance with a legal obligation pursuant to Article
        6(1)(c) of the GDPR, as well as Section 169(2) of the Accounting Act and Section 169 of the VAT Act.</p>
      <p class="block_20">In the case of the identification and contacting of the user as a customer and liaising, and
        the fulfilment of the product (or service) ordered, the consent of the data subject pursuant to Article 6(1)(a)
        of the GDPR and the performance of a contract pursuant to Article 6(1)(b) of the GDPR.</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_19">Duration of processing</p>
      <p class="block_20">Until the agreement is fulfilled, until the withdrawal of the consent of the data subject, or
        in the absence of such withdrawal, the Controller shall erase the data after 5 years from the date of purchase,
        pursuant to Section 6:22 of the Civil Code.</p>
      <p class="block_20">&nbsp;</p>
      <p class="block_20">If the Controller is obliged to retain the data under the Accounting Act, the Controller
        erases the data only after 8 years following the termination of the user account, regardless of the consent of
        the data subject.</p>
      <p class="block_14"><span
        class="text_11">&nbsp;Consent can be withdrawn at any time by sending an email to </span><span class="text_12">info@anja.hu</span><span
        class="text_11">.</span></p>
      <p class="block_14">&nbsp;</p>
      <p class="block_21">Scope of persons authorised to access the data</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_20">The company's customer service and billing/invoicing staff</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_22">Data transmission:</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_23">For purchases made by bank card:</p>
      <p class="block_20"><span class="text_4">In connection with the fulfilment of the online sale of products as the purpose of processing, the data relating to bank card purchases made online using SimplePay. The operator of the SimplePay system and the provider of the SimplePay service is OTP Mobil Szolgáltató Korlátolt Felelősségű Társaság (OTP Mobil Kft., hereinafter: SimplePay). Data are transmitted via the bank car acceptance network of OTP Mobil Kft. (H-1143 Budapest, Hungária krt. 17-19) for the purposes of financial processing of the transaction, transaction security and transaction tracking. The scope of data transmitted:</span>
        <span class="text_4">first name, last name, email address, payment transaction data.</span></p>
      <p class="block_23">For delivery:</p>
      <p class="block_20">In the case of the delivery of products, the name, address, telephone number of the recipient
        and the value of the order may be transmitted to the following recipients:</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_22">SPRINTER Futárszolgálat Korlátolt Felelősségű Társaság</p>
      <p class="block_24">H-1097 Budapest, Táblás utca 39</p>
      <p class="block_22">DPD Hungária Futárpostai, Csomagküldő Szolgáltató Korlátolt Felelősségű Társaság</p>
      <p class="block_24">H-1134 Budapest, Váci út 33, 2. emelet</p>
      <p class="block_22">DHL Express Magyarország Szállítmányozó és Szolgáltató Kft.</p>
      <div class="calibre9">
        <div class="block_9"><span class="bullet_">1185&nbsp;</span><span class="calibre3">Budapest, BUD International Airport, Building 302</span>
        </div>
      </div>
      <p class="block_22">GLS General Logistics Systems Hungary Kft.</p>
      <p class="block_10">H-2351 Alsónémedi, GLS Európa utca 2</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_21">Use of Processors: See chapter on processors. </p>
      <p class="block_14">&nbsp;</p>
      <div class="calibre6">
        <div class="block_13" id="calibre_link-10"><span class="bullet_1">.2.&nbsp;</span><span class="calibre7">Personal data provided during the complaints handling process </span>
        </div>
      </div>
      <p class="block_14">&nbsp;</p>
      <p class="block_14"><span class="text_1">Purpose of processing:</span><span class="text_13"> </span><span
        class="text_">To investigate and accurately document the circumstances of any complaint received to the company's contact email address (info@anja.hu) in order to make available to the Controller requests and comments relating to the Controller's activities.</span>
      </p>
      <p class="block_20">The communication is archived and as such, in the event of any subsequent questions or
        disputes, the information is available in its original form and the Controller can contact the user if necessary
        in connection with the matter.</p>
      <p class="block_25"><b class="calibre1">Scope of data processed: </b>It is not necessary to process all of the
        following data in the event of a complaint, but the following data may be received by the Controller:</p>
      <p class="block_25">a) the name and address of the consumer,</p>
      <p class="block_25">b) the place, time, and form of lodging the complaint,</p>
      <p class="block_25">c) the detailed description of the consumer's complaint, a list of the documents, records and
        other evidence produced by the consumer,</p>
      <p class="block_25">d) a statement by the Controller of its position on the consumer's complaint, provided an
        immediate investigation of the complaint is possible,</p>
      <p class="block_25">e) the signatures of the person drawing up the report and the consumer (unless the complaint
        was made via email or over the phone),</p>
      <p class="block_25">f) the place, date, and time of the drawing up of the report,</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Legal basis for processing:</b> The legal basis for the processing of data
        processed in the course of complaints handling activities is the consent of the data subject pursuant to Article
        6(1)(a) of the GDPR, the performance of a contract pursuant to Article 6(1)(b) of the GDPR, and compliance with
        a legal obligation pursuant to Article 6(1)(c) of the GDPR and Section 17/A of the Consumer Protection Act.</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Duration of processing: </b>The Controller shall retain the report
        recording the complaint as well as a copy of its response for a period of five years, and it shall present them
        to investigating authorities upon request.</p>
      <p class="block_25"> If the Controller is obliged to retain the data under the Accounting Act, the Controller
        erases the data only after 8 years following the lodging of the complaint, regardless of the consent of the data
        subject.</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Scope of persons authorised to access the data: </b>The company's
        complaints handling staff</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Data transmission: </b>none<b class="calibre1"> </b></p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Use of Processors: </b>See chapter on processors.<b class="calibre1"> </b>
      </p>
      <p class="block_26">&nbsp;</p>
      <p class="block_14">&nbsp;</p>
      <div class="calibre6">
        <div class="block_13" id="calibre_link-11"><span class="bullet_1">.3.&nbsp;</span><span class="calibre7">Processing of data when subscribing to a newsletter</span>
        </div>
      </div>
      <p class="block_14">&nbsp;</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_20"><b class="calibre1"><span class="calibre4">Purpose of processing:  </span></b>Customers can
        subscribe to newsletters concurrently as their registration on the website or without registration. The main
        purpose of processing is for the Controller to send marketing content to data subjects. The Controller may use
        the data for marketing research and surveys. In accordance with the applicable legal provisions, the Controller
        keeps records on natural persons who have subscribed to the newsletter service. The Controller does not send
        newsletters to natural persons not included in such records.</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Scope of data processed: </b>Name, email address</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Legal basis for processing: </b>Consent pursuant to Section 5(1)(a) of the
        Privacy Act, Article 6(1)(a) of the GDPR, Section 13/A of the E-commerce Act and Section 6(1) of the Advertising
        Act.</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Duration of processing: </b>Until the right to object is exercised or
        until the data subject voluntarily unsubscribes.</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Scope of persons authorised to access the data:</b> The company's
        employees responsible for sending newsletters</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Data transmission: </b>None</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Use of Processors: </b>See chapter on processors.<b class="calibre1"> </b>
      </p>
      <p class="block_14">&nbsp;</p>
      <p class="block_27">&nbsp;</p>
      <div class="calibre6">
        <div class="block_13" id="calibre_link-12"><span class="bullet_1">.4.&nbsp;</span><span class="calibre7">Data processing operations during contact and liaising</span>
        </div>
      </div>
      <p class="block_14">&nbsp;</p>
      <p class="block_20">The company can be contacted through various communication channels, e.g. email, phone, social
        media. In case of contact over the phone, the conversation is not recorded.</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_20"><b class="calibre1">Purpose of processing: </b>Serving people interested in the company's
        products and services, answering their questions, advising on product purchases and lifestyle management.</p>
      <p class="block_25"><b class="calibre1">Scope of data processed: </b>The personal data provided during contact
        typically include: Name, email address, telephone number and in some cases, for more accurate lifestyle
        consultancy, an identifiable photograph of the data subject. </p>
      <p class="block_25"><b class="calibre1">Legal basis for processing: </b>Consent granted by the data subject
        pursuant to Article 6(1)(a) of the GDPR. As contact is always initiated by the data subject, the provision of
        personal data is considered voluntary and consent to processing is given. </p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Duration of processing: </b>Until the given question or query is answered.
      </p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Scope of persons authorised to access the data: </b>The company's customer
        service staff</p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Data transmission: </b>none </p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Period of data retention: </b>Until the given question or query is
        answered, after which the Controller erases the personal data received. </p>
      <p class="block_26">&nbsp;</p>
      <p class="block_25"><b class="calibre1">Use of Processors: </b>See chapter on processors. </p>
      <p class="block_14">&nbsp;</p>
      <p class="block_14">&nbsp;</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_1">&nbsp;</p>
      <div class="calibre6">
        <div class="block_13" id="calibre_link-13"><span class="bullet_1">.5.&nbsp;</span><span class="calibre7"> Data processing related to cookie management</span>
        </div>
      </div>
      <p class="block_4">&nbsp;</p>
      <p class="block_28">Cookies are short text files consisting of letters and numbers that are downloaded to the
        browser of a computer, mobile device or other device by the online stores visited by the user. Cookies may be
        installed based on a request sent by the user's device to the server hosting the Herman Pharma Kft. website or
        to a third-party server. Cookies can be divided into 3 broader categories:<br class="calibre5"/><br
          class="calibre5"/><b class="calibre1">Strictly necessary cookies:</b><br class="calibre5"/>To ensure the
        correct functioning of the website, we need to use cookies. Cookies without which the website would not function
        properly are called strictly necessary.</p>
      <p class="block_28">Strictly necessary cookies may control, for example, the following functions:</p>
      <div class="calibre8">
        <div class="block_29"><span class="bullet_">-&nbsp;</span><span class="calibre3">whether or not to again display the login page for the visitor</span>
        </div>
        <div class="block_29"><span class="bullet_">-&nbsp;</span><span
          class="calibre3">list of wishlisted products</span></div>
        <div class="block_29"><span class="bullet_">-&nbsp;</span><span class="calibre3">the current language of the shop, which can be set by the customer</span>
        </div>
        <div class="block_29"><span class="bullet_">-&nbsp;</span><span class="calibre3">etc.</span></div>
      </div>
      <p class="block_28"><b class="calibre1">Statistical cookies:</b><br class="calibre5"/>Statistical cookies collect
        information about how visitors use our online store. These cookies cannot accurately identify the user.
        Information collected by statistical cookies includes page views, clicks, session length, time of visit, etc.&nbsp;
      </p>
      <p class="block_28"><b class="calibre1">Marketing cookies:</b><br class="calibre5"/>Marketing cookies help provide
        visitors of the online store with a more pleasant browsing experience, including by displaying personalised
        offers and advertisements.</p>
      <p class="block_30"><span class="text_14">The following cookies are loaded when visiting the </span><a
        href="http://www.anja.hu" class="text_7">www.anja.hu</a><span class="text_2"> </span><span class="text_14"> website:</span>
      </p>
      <table class="table_">
        <tbody class="calibre10">
        <tr class="calibre11">
          <td colspan="6" class="td_"><p class="block_31">Information required for COOKIE policy </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_32">Cookie name </p></td>
          <td class="td_1"><p class="block_32">Cookie owner </p></td>
          <td class="td_1"><p class="block_32">Category </p></td>
          <td class="td_1"><p class="block_32">Purpose </p></td>
          <td class="td_1"><p class="block_32">What data are collected </p></td>
          <td class="td_1"><p class="block_32">Validity </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">_ga </p></td>
          <td class="td_1"><p class="block_33">Google </p></td>
          <td class="td_1"><p class="block_33">statistical </p></td>
          <td class="td_1"><p class="block_34">https://developers.google.com/analytics/devguides
            /collection/analyticsjs/cookie-usage </p>
            <p class="block_33">We use these cookies to track visits to our site and the sources of traffic, in order to
              measure and improve the performance of our site. The cookies help us identify the most and least popular
              pages and monitor the movement of visitors across the site. The information collected by the cookies is
              aggregated and, therefore, anonymous. If you do not allow the use of cookies, we will not be notified that
              you have visited our site. </p></td>
          <td class="td_1"><p class="block_33">Collects anonymous data </p></td>
          <td class="td_1"><p class="block_33">23 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">_fbp </p></td>
          <td class="td_1"><p class="block_33">Facebook </p></td>
          <td class="td_1"><p class="block_33">Marketing </p></td>
          <td class="td_1"><p class="block_34">https://developers.facebook.com/docs/marketing-
            api/conversions-api/parameters/fbp-and-fbc </p></td>
          <td class="td_1"><p class="block_33">Generates a unique identifier to display ads relevant to the user </p>
          </td>
          <td class="td_1"><p class="block_33">2 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">websiteCookiesAccepted </p></td>
          <td class="td_1"><p class="block_33">Angular.js </p></td>
          <td class="td_1"><p class="block_33">necessary </p></td>
          <td class="td_1"><p class="block_33">Records the fact of acceptance of the cookie banner </p></td>
          <td class="td_1"><p class="block_33">IP address </p></td>
          <td class="td_1"><p class="block_33">119 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">_gac_UA- 152956325-1 </p></td>
          <td class="td_1"><p class="block_33">Google </p></td>
          <td class="td_1"><p class="block_33">statistical </p></td>
          <td class="td_1"><p class="block_34">https://support.google.com/google-ads/answer /7521212 </p></td>
          <td class="td_1"><p class="block_33">Generates a unique identifier, which provides information about when the
            user clicked on Google paid ads </p></td>
          <td class="td_1"><p class="block_33">2 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">SimplePay </p></td>
          <td class="td_1"><p class="block_33">OTP Mobil Kft. </p></td>
          <td class="td_1"><p class="block_33">necessary </p></td>
          <td class="td_1"><p class="block_33">In case of a purchase, data are transmitted for payment. </p></td>
          <td class="td_1"><p class="block_33">IP address, name, value of products </p></td>
          <td class="td_1"><p class="block_33">11 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">_gid </p></td>
          <td class="td_1"><p class="block_33">Google </p></td>
          <td class="td_1"><p class="block_33">statistical </p></td>
          <td class="td_1"><p class="block_34">https://developers.google.com/analytics/devguides
            /collection/analyticsjs/cookie-usage </p>
            <p class="block_33">We use these cookies to track visits to our site and the sources of traffic, in order to
              measure and improve the performance of our site. The cookies help us identify the most and least popular
              pages and monitor the movement of visitors across the site. The information collected by the cookies is
              aggregated and, therefore, anonymous. If you do not allow the use of cookies, we will not be notified that
              you have visited our site. </p></td>
          <td class="td_1"><p class="block_33">Collects anonymous data </p></td>
          <td class="td_1"><p class="block_33">11 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">api_token </p></td>
          <td class="td_1"><p class="block_33">OpenCart </p></td>
          <td class="td_1"><p class="block_33">necessary </p></td>
          <td class="td_1"><p class="block_33">this is how we identify the user on the backend, this is how they can
            place an order </p></td>
          <td class="td_1"><p class="block_33">stores only a single unique token </p></td>
          <td class="td_1"><p class="block_33">1 hour </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">wishlist </p></td>
          <td class="td_1"><p class="block_33">OpenCart </p></td>
          <td class="td_1"><p class="block_33">necessary </p></td>
          <td class="td_1"><p class="block_33">this is where the user's wishlisted products are stored </p></td>
          <td class="td_1"><p class="block_33">stores the product identifiers </p></td>
          <td class="td_1"><p class="block_33">12 months </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">currency </p></td>
          <td class="td_1"><p class="block_33">OpenCart </p></td>
          <td class="td_1"><p class="block_33">necessary </p></td>
          <td class="td_1"><p class="block_33">user’s currency </p></td>
          <td class="td_1"><p class="block_33">currency </p></td>
          <td class="td_1"><p class="block_33">3 weeks </p></td>
        </tr>
        <tr class="calibre11">
          <td class="td_1"><p class="block_33">language </p></td>
          <td class="td_1"><p class="block_33">OpenCart </p></td>
          <td class="td_1"><p class="block_33">necessary </p></td>
          <td class="td_1"><p class="block_33">user’s language </p></td>
          <td class="td_1"><p class="block_33">language identifier </p></td>
          <td class="td_1"><p class="block_33">3 weeks </p></td>
        </tr>
        <tr class="calibre11">
          <td colspan="6" class="td_2"><p class="block_26">&nbsp;</p></td>
        </tr>
        </tbody>
      </table>
      <p class="block_30">&nbsp;</p>
      <h1 class="block_" id="calibre_link-14">6. Information on the rights of data subjects</h1>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-15" class="block_35">The right to information and access to the personal data processed:</p>
      <p class="block_2">The data subject shall have the right to obtain from the Controller confirmation as to whether
        or not personal data concerning them are being processed, and, where that is the case, access to the personal
        data and the following information:</p>
      <p class="block_6">a) the purposes of the processing;</p>
      <p class="block_6">b) the categories of personal data concerned;</p>
      <p class="block_6">c) the recipients or categories of recipient to whom the personal data have been or will be
        disclosed, in particular recipients in third countries or international organisations;</p>
      <p class="block_6">d) where possible, the envisaged period for which the personal data will be stored, or, if not
        possible, the criteria used to determine that period;</p>
      <p class="block_6">e) the existence of the right to request from the Controller rectification or erasure of
        personal data or restriction of processing of personal data concerning the data subject or to object to such
        processing;</p>
      <p class="block_6">f) the right to lodge a complaint with a supervisory authority;</p>
      <p class="block_6">g) where the personal data are not collected from the data subject, any available information
        as to their source;</p>
      <p class="block_6">h) the existence of automated decision-making, including profiling, and, at least in those
        cases, comprehensible information about the logic applied, as well as the significance and the envisaged
        consequences of such processing for the data subject.</p>
      <p class="block_2">Where personal data are transferred to a third country or to an international organisation, the
        data subject shall have the right to be informed of the appropriate safeguards relating to the data
        transfer.</p>
      <p class="block_2">The Controller shall provide the data subject with a copy of the personal data undergoing
        processing. For any further copies requested by the data subject, the Controller may charge a reasonable fee
        based on administrative costs. Where the data subject makes the request by electronic means, and unless
        otherwise requested by the data subject, the information shall be provided by the Controller in a commonly used
        electronic form.</p>
      <p class="block_2">The right to obtain a copy referred to in the previous Subsection shall not adversely affect
        the rights and freedoms of others.</p>
      <p id="calibre_link-16" class="block_1">&nbsp;</p>
      <p class="block_35">Right to rectification</p>
      <p class="block_2">The Controller shall, at the request of the data subject, correct personal data which are
        inaccurate concerning the data subject without undue delay. Taking the purpose of processing into account, the
        data subject shall have the right to have incomplete personal data completed, including by means of providing a
        supplementary statement.</p>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-17" class="block_35">Right to erasure (right to be forgotten):</p>
      <p class="block_2">The data subject shall have the right, upon request and without undue delay, to obtain the
        erasure of personal data concerning them by the Controller on one of the following grounds:</p>
      <p class="block_6">a) the personal data are no longer necessary in relation to the purposes for which they were
        collected or otherwise processed;</p>
      <p class="block_6">b) the data subject withdraws the consent on which the processing is based, and there is no
        other legal ground for the processing;</p>
      <p class="block_6">c) the data subject objects to the processing, and there are no overriding grounds for the
        processing or where the processing would be for direct marketing purposes;</p>
      <p class="block_6">d) the personal data have been unlawfully processed;</p>
      <p class="block_6">e) the personal data have to be erased for compliance with a legal obligation in Union or
        Member State law to which the Controller is subject;</p>
      <p class="block_6">f) the personal data have been collected in relation to the offer of information society
        services.</p>
      <p class="block_2">The erasure of data cannot be initiated if the processing is necessary:</p>
      <p class="block_6">a) for exercising the right of freedom of expression and information;</p>
      <p class="block_6">b) for compliance with a legal obligation which requires processing by Union or Member State
        law to which the Controller is subject or for the performance of a task carried out in the public interest;</p>
      <p class="block_6">c) for the purposes of preventive or occupational medicine, for the assessment of the working
        capacity of the employee, medical diagnosis, the provision of health or social care or treatment or the
        management of health or social care systems and services on the basis of Union or Member State law or pursuant
        to contract with a health professional, and where such data are processed by or under the responsibility of a
        professional subject to the obligation of professional secrecy under Union or Member State law or rules
        established by national competent bodies or by another person also subject to an obligation of secrecy under
        Union or Member&nbsp;State law or rules established by national competent bodies;</p>
      <p class="block_6">d) for reasons of public interest in the area of public health, such as protecting against
        serious cross-border threats to health or ensuring high standards of quality and safety of health care and of
        medicinal products or medical devices, on the basis of Union or Member State law which provides for suitable and
        specific measures to safeguard the rights and freedoms of the data subject, in particular professional
        secrecy;</p>
      <p class="block_6">e) for reasons of public interest in the area of public health, where such data are processed
        by or under the responsibility of a professional subject to the obligation of professional secrecy under Union
        or Member State law or rules established by national competent bodies or by another person also subject to an
        obligation of secrecy under Union or Member&nbsp;State law or rules established by national competent
        bodies;</p>
      <p class="block_6"><span class="text_15">f) for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in so far as the data subject’s right to erasure is likely to render impossible or seriously impair the achievement of the objectives of that processing</span><span
        class="text_16"><sup class="calibre12">1</sup></span><span class="text_15">; or</span></p>
      <p class="block_6">g) for the establishment, exercise, or defence of legal claims.</p>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-18" class="block_35">Right to restriction of processing:</p>
      <p class="block_2">The data subject shall have the right to obtain from the Controller restriction of processing
        where one of the following applies:</p>
      <p class="block_6">a) the accuracy of the personal data is contested by the data subject, for a period enabling
        the data subject to verify the accuracy of the personal data;</p>
      <p class="block_6">b) the processing is unlawful and the data subject opposes the erasure of the personal data and
        requests the restriction of their use instead;</p>
      <p class="block_6">c) the Controller no longer needs the personal data for the purposes of the processing, but
        they are required by the data subject for the establishment, exercise, or defence of legal claims; or</p>
      <p class="block_6">(d) the data subject has objected to the processing in relation to the processing based on the
        public interest or legitimate interest of the Controller; in this case, the restriction shall apply for the
        period until it is established whether the legitimate grounds of the controller override those of the data
        subject.</p>
      <p class="block_2">Where processing has been restricted on the basis of the above, such personal data shall, with
        the exception of storage, only be processed with the data subject's consent or for the establishment, exercise
        or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons
        of important public interest in the European Union or a Member&nbsp;State.</p>
      <p class="block_2">A data subject who has obtained restriction of processing on the basis of the above shall be
        informed by the Controller in advance of the lifting of the restriction on processing.</p>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-19" class="block_35">Right to data portability:</p>
      <p class="block_2">The data subject shall have the right to receive the personal data concerning them, which they
        have provided to the Controller, in a structured, commonly used and machine-readable format and have the right
        to transmit those data to another controller without hindrance from the Controller to which the personal data
        have been provided, where:</p>
      <p class="block_6">a) processing is based on consent or a contract; and</p>
      <p class="block_6">b) the processing is carried out by automated means.</p>
      <p class="block_2">In exercising their right to data portability in accordance with the above, the data subject
        shall have the right to have the personal data transmitted directly from one controller to another, where
        technically feasible.</p>
      <p class="block_2">The exercise of the right to data portability shall be without prejudice to the right to
        erasure (“right to be forgotten”). That right shall not apply to processing necessary for the performance of a
        task carried out in the public interest or in the exercise of official authority vested in the Controller.</p>
      <p class="block_2">The right to data portability shall not adversely affect the rights and freedoms of others.</p>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-20" class="block_35">Right to object:</p>
      <p class="block_2">The data subject shall have the right to object at any time, on grounds relating to their
        particular situation, to the processing of their personal data by the Controller where the legal basis for
        processing is the performance of a task carried out in the public interest or in the exercise of official
        authority vested in the controller, or the need to pursue the legitimate interests of the Controller or of a
        third party, including profiling based on the aforementioned provisions. In this case, the Controller shall no
        longer process the personal data unless the Controller demonstrates compelling legitimate grounds for the
        processing which override the interests, rights, and freedoms of the data subject or for the establishment,
        exercise, or defence of legal claims.&nbsp;</p>
      <p class="block_2">Where personal data are processed for direct marketing purposes, the data subject shall have
        the right to object at any time to processing of personal data concerning them for such marketing, which
        includes profiling to the extent that it is related to such direct marketing. Where the data subject objects to
        processing for direct marketing purposes, the personal data shall no longer be processed for such purposes.</p>
      <p class="block_2">Where personal data are processed for scientific or historical research purposes or statistical
        purposes, the data subject, on grounds relating to their particular situation, shall have the right to object to
        processing of personal data concerning them, unless the processing is necessary for the performance of a task
        carried out for reasons of public interest.</p>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-21" class="block_35">Right to withdraw consent:</p>
      <p class="block_2">The data subject shall have the right to withdraw their consent at any time if the Controller's
        processing is based on the data subject's consent. The withdrawal of consent does not affect the legitimacy of
        any processing that was conducted based on the consent prior to its withdrawal.</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_35">Procedure in the event of data subject request concerning the exercise of the above
        rights:</p>
      <p class="block_2">The Controller shall inform the data subject of the measures taken in response to the data
        subject's request concerning the exercise of the rights set out in this Policy without undue delay and in any
        event within one month (30 days) of receipt of the request. That period may be extended by two additional months
        where necessary, taking into account the complexity and number of the requests.</p>
      <p class="block_2">The Controller shall inform the data subject of any such extension within one month of receipt
        of the request, together with the reasons for the delay. That period may be extended by two additional months
        (60 days) where necessary, taking into account the complexity and number of the requests. Where the data subject
        makes the request by electronic means, the information shall be provided by electronic means where possible,
        unless otherwise requested by the data subject.</p>
      <p class="block_2">If the Controller does not take action on the request of the data subject, the Controller shall
        inform the data subject without delay and at the latest within one month of receipt of the request of the
        reasons for not taking action and on the possibility of lodging a complaint with a supervisory authority and
        seeking a judicial remedy.</p>
      <p class="block_2">The Controller shall provide the requested information free of charge, with the proviso that
        where requests from a data subject are manifestly unfounded or excessive, in particular because of their
        repetitive character, the Controller may charge a reasonable fee, taking into account the administrative costs
        of providing the requested information or of taking the requested action, or may refuse to act on the
        request.</p>
      <p class="block_2">The Controller shall communicate any rectification or erasure of personal data or restriction
        of processing by it to each recipient to whom the personal data have been disclosed, unless this proves
        impossible or involves disproportionate effort. The Controller shall inform the data subject about such
        recipients if the data subject requests.</p>
      <p class="block_1"><span class="text_">Any questions or requests Customers may have about their personal data stored in the system and about data processing should be sent to </span><span
        class="text_17">info@anja.hu</span><span class="text_">. Customers should bear in mind that &ndash; with a view to Customer interests &ndash; we can only provide information or take action regarding the processing of personal data credible proof of identity is provided.</span>
      </p>
      <p class="block_2">We will &ndash; in each case &ndash; require the following information to respond to
        requests:</p>
      <ul class="list_">
        <li class="block_36">the email address provided at registration</li>
        <li class="block_36">full name</li>
        <li class="block_36">billing address</li>
      </ul>
      <p class="block_2">Please note, requests must be sent from the email address provided at registration.</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_2">&nbsp;</p>
      <h1 id="calibre_link-22" class="block_"><span class="calibre13">7.  Data security measures:</span></h1>
      <p class="block_2">The Controller and the server network operator shall protect the data, in particular against
        unauthorised access, alteration, disclosure, disclosure, deletion or destruction, accidental destruction or
        accidental damage, using reasonably available state-of-the-art hardware and software, in order to ensure data
        security. As a general rule, the data processed by the Controller may be disclosed only to the employees and
        other collaborators of the Controller involved in the accomplishment of the data processing objectives as
        defined herein, who are bound by confidentiality obligations in respect of all data disclosed to them on the
        basis of their employment contract or their employment relationship, as well as other contractual relationships,
        legal provisions or the instructions of the Controller.</p>
      <p class="block_2">All processing activities of the Controller must be accurately documented. The Controller must
        keep records of all data processing activities it carries out (e.g. newsletter, online store, employee records).
        For the purposes of monitoring the lawfulness of data transfer and informing the data subject, the Controller
        shall keep a data transfer register containing the date of transfer of the processed data, the legal basis, the
        recipient, the scope of the data, and other data specified in the legislation stipulating the processing.</p>
      <p class="block_2">&nbsp;</p>
      <h2 id="calibre_link-23" class="block_7">7.1 Security of personal data processed on paper</h2>
      <p class="block_2">In order to ensure the security of personal data processed on paper, the Controller applies the
        following measures:</p>
      <p class="block_6">• data can only be accessed by authorised persons and cannot be accessed by or disclosed to
        others,</p>
      <p class="block_6">• documents are stored in a well-locked, dry room with fire and property protection
        equipment,</p>
      <p class="block_6">• only competent staff have access to documents that are undergoing permanent active
        processing,</p>
      <p class="block_6">• the staff member performing processing may only leave the room where the processing is taking
        place during the day by locking away the data carriers entrusted to them or by locking the office,</p>
      <p class="block_6">• where personal data processed on paper are digitalised, the Controller applies the security
        rules applicable to digitally stored documents and requires its data processors to do the same.</p>
      <p class="block_2">&nbsp;</p>
      <h2 id="calibre_link-24" class="block_7">7.2 Security of digitally stored personal data</h2>
      <p class="block_2">In order to ensure the security of personal data stored on computers or networks, the
        Controller takes the following measures: </p>
      <ul class="list_">
        <li class="block_36">access to the data stored in the online store system is only possible with valid, personal,
          identifiable authorisation &ndash; at least a username and a password,
        </li>
        <li class="block_36">all access to the data is logged in a traceable manner,</li>
        <li class="block_36">virus protection on the network that processes personal data is ensured on an ongoing
          basis,
        </li>
        <li class="block_36">unauthorised persons are prevented from gaining access to the network by using the
          available IT devices and tools.
        </li>
      </ul>
      <p class="block_2">&nbsp;</p>
      <p id="calibre_link-25" class="block_37">&nbsp;</p>
      <h1 class="block_" id="calibre_link-26">8. Processors</h1>
      <p class="block_38">The Controller shall use the Processors designated herein to carry out its activities.
        Processors do not take any independent decisions and are only entitled to act in accordance with the agreement
        concluded with the Controller and the instructions received from the Controller. The Controller shall monitor
        the work of Processors. Processors shall only be entitled to use additional processors with prior written
        consent granted by the Controller.</p>
      <p class="block_1">&nbsp;</p>
      <p class="block_1">&nbsp;</p>
      <table class="table_1">
        <tbody class="calibre10">
        <tr class="tr_">
          <td colspan="2" class="td_3"><p id="calibre_link-27" class="block_39">Processors</p></td>
          <td colspan="4" class="td_4"><p class="block_39">What personal data do they have access to? In what way can
            they use the personal data (what activities do they perform for the Controller)?</p></td>
          <td class="td_5"><p class="block_39">How long can they store the data?</p></td>
        </tr>
        <tr class="tr_">
          <td class="td_6"><p class="block_22">SPRINTER Futárszolgálat Korlátolt Felelősségű Társaság</p>
            <p class="block_25">H-1097 Budapest, Táblás utca 39</p>
            <p class="block_26">&nbsp;</p>
            <p class="block_22">DPD Hungária Futárpostai, Csomagküldő Szolgáltató Korlátolt Felelősségű Társaság</p>
            <p class="block_25">H-1134 Budapest, Váci út 33, 2. floor</p>
            <p class="block_26">&nbsp;</p>
            <p class="block_22">DHL Express Magyarország Szállítmányozó és Szolgáltató Kft.</p>
            <p class="block_25">H-1185 Budapest, BUD International Airport, Building 302</p>
            <p class="block_26">&nbsp;</p>
            <p class="block_22">GLS General Logistics Systems Hungary Kft.</p>
            <p class="block_25">H-2351 Alsónémedi, GLS Európa utca 2</p>
            <p class="block_26">&nbsp;</p></td>
          <td colspan="4" class="td_7"><p class="block_40">We use these courier services to deliver the products
            purchased by Customers. We provide courier services with the information that is absolutely necessary to
            successfully deliver the goods and notify the Customer: Name, email address, telephone number, delivery
            address</p></td>
          <td colspan="2" class="td_8"><p class="block_40">They store the data until the delivery objective is
            accomplished. </p></td>
        </tr>
        <tr class="tr_">
          <td colspan="2" class="td_6"><p class="block_41">Számlázz.hu </p>
            <p class="block_41">KBOSS.hu Kft.</p>
            <p class="block_14">&nbsp;</p></td>
          <td colspan="4" class="td_9"><p class="block_42">It operates our invoicing/billing software, and as such its
            systems will have the personal data needed to issue invoices: Name, address</p></td>
          <td class="td_10"><p class="block_42">Due to legal obligations, we are obliged to retain invoice data for the
            period specified in the Accounting Act.</p></td>
        </tr>
        <tr class="tr_">
          <td colspan="3" class="td_6"><p class="block_14">&nbsp;</p></td>
          <td class="td_9"><p class="block_42">The hosting provider of our website and our mailing system. It provides
            the necessary conditions for the operation of these systems, has the technical ability to access email data,
            but does not do so under any circumstances. </p></td>
          <td colspan="3" class="td_10"><p class="block_42">As long as we have an active and effective agreement with
            the company. </p></td>
        </tr>
        <tr class="tr_">
          <td colspan="3" class="td_6"><p class="block_41">Prog-Oak Kft.</p>
            <p class="block_42">H-6724 Szeged, Kátay u. 24</p></td>
          <td class="td_9"><p class="block_42">The contractor in charge of the development and maintenance of our
            website. It has the technical ability to access the website's databases, but does not do so under any
            circumstances. </p></td>
          <td colspan="3" class="td_10"><p class="block_42">As long as we have an active and effective agreement with
            the company.</p></td>
        </tr>
        <tr class="tr_">
          <td colspan="3" class="td_11"><p class="block_41">MailChimp company</p>
            <p class="block_42">Atlanta, United States</p></td>
          <td class="td_12"><p class="block_42">We use the MailChimp application to send newsletters, meaning that the
            name and email address of Customers who subscribe to our newsletter service is stored in the application and
            used solely for the purpose of sending our newsletter. </p></td>
          <td colspan="3" class="td_13"><p class="block_42">As long as we have an active and effective agreement with
            the company. If we choose another provider, MailChimp will be obliged to erase all our data. </p></td>
        </tr>
        </tbody>
      </table>
      <h1 id="calibre_link-28" class="block_">9. Personal data breach</h1>
      <p class="block_43">Personal data breach: a breach of security leading to the accidental or unlawful destruction,
        loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored, or otherwise
        processed.</p>
      <p class="block_43">When the personal data breach is likely to result in a high risk to the rights and freedoms of
        natural persons, the Controller shall communicate the personal data breach to the data subject without undue
        delay, in a clear and plain language.</p>
      <p class="block_43">The communication to the data subject shall not be required if any of the following conditions
        are met:</p>
      <p class="block_43">a) the Controller has implemented appropriate technical and organisational protection
        measures, and those measures were applied to the personal data affected by the personal data breach, in
        particular those that render the personal data unintelligible to any person who is not authorised to access it,
        such as encryption;</p>
      <p class="block_43">b) the Controller has taken subsequent measures which ensure that the high risk to the rights
        and freedoms of data subjects is no longer likely to materialise;</p>
      <p class="block_43">c) it would involve a disproportionate effort. In such cases, there shall instead be a public
        communication or similar measure whereby the data subjects are informed in an equally effective manner.</p>
      <p class="block_4">&nbsp;</p>
      <h1 class="block_" id="calibre_link-29">10. Avenues for legal remedy</h1>
      <p class="block_43">a) In case of any questions or comments concerning data processing, the Controller may be
        contacted using one of the contact details provided herein.</p>
      <p class="block_43">b) Proceedings may be opened by the Customer with the Hungarian National Authority for Data
        Protection and Freedom of Information (postal address: H-1374 Budapest, Pf. 603, telephone: +36-1-391-1400,
        email: ugyfelszolgalat@naih.hu, website: www.naih.hu), citing that their personal data were processed in an
        infringing manner or there is a direct threat thereof.</p>
      <p class="block_43">c) In the event of the violation of their rights, the data subject may turn to the court
        against the Controller. The court proceeds in the action as a matter of urgency. The Controller is required to
        prove that the processing complies with legal provisions. The litigation procedure falls within the jurisdiction
        of the tribunal. The action may also be brought, at the discretion of the data subject, before the regional
        court with jurisdiction at the data subject’s residence or place of stay.</p>
      <p class="block_4">&nbsp;</p>
      <p class="block_4">&nbsp;</p>

    </div>
  </section>

  <section *ngIf="lang === 'hu-hu'" class="container">
    <h1 class="title">
      Adatkezelési tájékoztató
    </h1>
    <h3 (click)="back()" class="back">{{"MENU.BACK" | translate}}</h3>
    <h1>
      1. BEVEZETÉS
    </h1>
    <p>
      Az <strong>Herman Pharma Kft.</strong> (székhely: 2363 Felsőpakony, utca
      806. hrsz. Adószám: 24689258-2-13, Cégjegyzékszám: 13 09 165671
      továbbiakban: „<strong>Adatkezelő</strong>”) a jelen adatvédelmi
      tájékoztatóban (a továbbiakban: „<strong>Tájékoztató</strong>”) ismerteti,
      hogy hogyan gyűjti, használja, adja át, továbbítja és tárolja az általa
      üzemeltetett <a href="http://www.anja.hu">www.anja.hu</a> weboldal
      látogatóinak adatait.
    </p>
    <p>
      Adatkezelő kijelenti, hogy a jelen tájékoztató megfelel a hatályos
      adatvédelmi szabályoknak.
    </p>
    <p>
      Adatkezelő bármikor jogosult a jelen tájékoztató egyoldalú
      megváltoztatására annak érdekében, hogy az a mindenkor hatályos jogszabályi
      rendelkezéseknek megfeleljen, ideértve az Adatkezelő szolgáltatásainak
      megváltozásával kapcsolatos módosításokat is. A jelen tájékoztató
      változásairól, a változással egyidejűleg tájékoztatjuk az érintetteket
      honlapunkon, a <a href="http://www.anja.hu">www.anja.hu</a> <strong> </strong>oldalon.
    </p>
    <p>
      Amennyiben kérdése merül fel a jelen Szabályzattal kapcsolatban, kérjük,
      írja meg nekünk az <a href="mailto:info@anja.hu">info@anja.hu</a> e-mail
      címre és adatvédelmi tisztviselőnk válaszol a kérdéseire. A jelen
      Szabályzat és annak mindenkori módosításai megtalálhatóak Társaság
      honlapján a<a href="http://www.anja.hu/adatvédelem">www.anja.hu/adatvédelem</a> <strong> </strong>címen.
    </p>
    <p>
      A jelen Szabályzat kialakításakor az Adatkezelő tekintettel volt az alábbi
      jogszabályokra:
    </p>
    <p>
      · a természetes személyeknek a személyes adatok kezelése tekintetében
      történő védelméről és az ilyen adatok szabad áramlásáról, valamint a
      95/46/EK rendelet hatályon kívül helyezéséről szóló 2016/679 Európai
      Parlament és Tanács (EU) rendelete (a továbbiakban: „<strong>GDPR</strong>
      ”),
    </p>
    <p>
      · az információs önrendelkezési jogról és az információszabadságról szóló
      2011. évi CXII. törvény („<strong>Infotv.</strong>”)
    </p>
    <p>
      · a Polgári Törvénykönyvről szóló 2013. évi V. törvény („ <strong>Ptk.</strong>”)
    </p>
    <p>
      · a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól
      szóló 2008. évi XLVIII. törvény („<strong>Grt.</strong>”),
    </p>
    <p>
      · az elektronikus kereskedelmi szolgáltatások, valamint információs
      társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi
      CVIII. törvény („<strong>Eker. tv.</strong>”)
    </p>
    <p>
      · a kutatás és a közvetlen üzletszerzés célját szolgáló név- és
      lakcímadatok kezeléséről szóló 1995. évi CXIX. törvény („ <strong>Direktmarketing tv.</strong>”)
    </p>
    <p>
      · a számvitelről szóló 2000. évi C. törvény („<strong>Számv. tv.</strong>”)
    </p>
    <p>
      · az adózás rendjéről szóló 2017. évi CL. törvény („<strong>Art.</strong>”)
    </p>
    <p>
      · a fogyasztóvédelemről szóló 1997. évi CLV. törvény („ <strong>Fgy. tv.</strong>”)
    </p>
    <p>
      · a postai szolgáltatásokról szóló 2012. évi CLIX. törvény („ <strong>Posta tv.</strong>”)
    </p>
    <p>
      · a hitelintézetekről és a pénzügyi vállalkozásokról szóló 2013. évi
      CCXXXVII. törvény („<strong>Hpt.</strong>”);
    </p>
    <p>
      · a fogyasztó és vállalkozás közötti szerződés keretében eladott dolgokra
      vonatkozó szavatossági és jótállási igények intézésének eljárási
      szabályairól szóló 19/2014. (IV. 29.) NGM rendelet („ <strong>Fgy. NGM rend.</strong>”);
    </p>
    <h1>
      2. FOGALOM-MEGHATÁROZÁSOK
    </h1>
    <p>
      A jelen Szabályzatban szereplő alábbi fogalmak a következő
      jelentéstartalommal bírnak
    </p>
    <p>
      „<strong>adatfeldolgozó</strong>”: az a természetes vagy jogi személy,
      közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő
      nevében személyes adatokat kezel;
    </p>
    <p>
      „<strong>adatkezelés</strong>”: a személyes adatokon vagy adatállományokon
      automatizált vagy nem automatizált módon végzett bármely művelet vagy
      műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás,
      tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés,
      felhasználás, közlés, továbbítás, terjesztés vagy egyéb módon történő
      hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás, korlátozás,
      törlés, illetve megsemmisítés;
    </p>
    <p>
      „<strong>adatkezelés korlátozása</strong>”: a tárolt személyes adatok
      megjelölése jövőbeli kezelésük korlátozása céljából;
    </p>
    <p>
      „<strong>adatkezelő</strong>”: az a természetes vagy jogi személy,
      közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a személyes
      adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt
      meghatározza; ha az adatkezelés céljait és eszközeit az uniós vagy hazai
      jog határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó
      különös szempontokat az uniós vagy a hazai jog is meghatározhatja;
    </p>
    <p>
      „<strong>adatvédelmi incidens</strong>”: a biztonság olyan sérülése, amely
      a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy
      jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan
      közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi.
    </p>
    <p>
      „<strong>címzett</strong>”: az a természetes vagy jogi személy, közhatalmi
      szerv, ügynökség vagy bármely egyéb szerv, akivel, vagy amellyel a
      személyes adatot közlik, függetlenül attól, hogy harmadik fél-e. Azon
      közhatalmi szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a
      hazai joggal összhangban férhetnek hozzá személyes adatokhoz, nem
      minősülnek címzettnek; az említett adatok e közhatalmi szervek általi
      kezelése meg kell, hogy feleljen az adatkezelés céljainak megfelelően az
      alkalmazandó adatvédelmi szabályoknak;
    </p>
    <p>
      „<strong>cookie</strong>”: a cookie egy rövid szöveges fájl, amit a
      webszerverünk elküld az érintett eszközére (legyen szó bármilyen
      számítógépről mobiltelefontról vagy tabletről) és olvas vissza. Vannak
      ideiglenes (munkamenet vagy más néven session) cookie-k, amelyek
      automatikusan törlődnek az eszközéről, amikor bezárja a böngészőt, és
      vannak hosszabb élettartamú cookie-k, amelyek hosszabb ideig maradnak az
      érintett eszközén (ez függ az érintett eszközének beállításaitól is);
    </p>
    <p>
      „<strong>érintett</strong>” a személyes adat alapján azonosított vagy -
      közvetlenül vagy közvetve - azonosítható személy, amely mindig egy
      meghatározott személy kell, hogy legyen. Kizárólag természetes személyek
      minősülnek érintettnek, tehát jogi személyek nem, ezáltal az adatvédelem
      csak a természetes személyek adatait védi. Viszont személyes adatnak
      minősül például az egyéni vállalkozó vagy egy cég képviselőjének adata is
      (pl. telefonszáma, email címe, születési helye, ideje stb.).
    </p>
    <p>
      „<strong>érintett hozzájárulása</strong>”: az érintett akaratának önkéntes,
      konkrét és megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása,
      amellyel az érintett nyilatkozat vagy a megerősítést félreérthetetlenül
      kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő
      személyes adatok kezeléséhez;
    </p>
    <p>
      „<strong>harmadik fél</strong>”: az a természetes vagy jogi személy,
      közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos az
      érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a
      személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen irányítása
      alatt a személyes adatok kezelésére felhatalmazást kaptak;
    </p>
    <p>
      „<strong>harmadik ország</strong>”: azon ország, amely az Európai Uniónak
      és az Európai Gazdasági Térségnek nem tagállama. Az Európai Unió tagállamai
      köthetnek olyan nemzetközi megállapodásokat, amelyek kiterjednek a
      személyes adatoknak a harmadik országok vagy a nemzetközi szervezetek
      részére történő továbbítására, ha e megállapodások nem érintik a GDPR vagy
      az uniós jog egyéb rendelkezéseit;
    </p>
    <p>
      „<strong>kötelező erejű vállalati szabályok</strong>”: a személyes adatok
      védelmére vonatkozó szabályzat, amelyet az Unió valamely tagállamának
      területén tevékenységi hellyel rendelkező adatkezelő vagy adatfeldolgozó
      egy vagy több harmadik országban a személyes adatoknak az ugyanazon
      vállalkozáscsoporton vagy közös gazdasági tevékenységet folytató
      vállalkozások ugyanazon csoportján belüli adatkezelő vagy adatfeldolgozó
      részéről történő továbbítása vagy ilyen továbbítások sorozata tekintetében
      követ;
    </p>
    <p>
      „<strong>személyes adat</strong>”: azonosított vagy azonosítható
      természetes személyre („érintett”) vonatkozó bármely információ;
      azonosítható az a természetes személy, aki közvetlen vagy közvetett módon,
      különösen valamely azonosító, például név, szám, helymeghatározó adat,
      online azonosító vagy a természetes személy testi, fiziológiai, genetikai,
      szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy
      vagy több tényező alapján azonosítható. A természetes személyek továbbá
      összefüggésbe hozhatók az általuk használt készülékek, alkalmazások,
      eszközök és protokollok által rendelkezésre bocsátott online azonosítókkal,
      például IP-címekkel és cookie-azonosítókkal, valamint egyéb azonosítókkal,
      például rádiófrekvenciás azonosító címkékkel. Ezáltal olyan nyomok
      keletkezhetnek, amelyek egyedi azonosítókkal és a szerverek által fogadott
      egyéb információkkal összekapcsolva felhasználhatók a természetes személyek
      profiljának létrehozására és az adott személy azonosítására;
    </p>
    <p>
      „<strong>nemzetközi szervezet</strong>”: a nemzetközi közjog hatálya alá
      tartozó szervezet vagy annak alárendelt szervei, vagy olyan egyéb szerv,
      amelyet két vagy több ország közötti megállapodás hozott létre, vagy amely
      ilyen megállapodás alapján jött létre;
    </p>
    <p>
      „<strong>nyilvántartási rendszer</strong>”: a személyes adatok bármely
      módon – centralizált, decentralizált vagy funkcionális, vagy földrajzi
      szempontok szerint – tagolt állománya, amely meghatározott ismérvek alapján
      hozzáférhető;
    </p>
    <p>
      „<strong>vállalkozás</strong>”: gazdasági tevékenységet folytató
      természetes vagy jogi személy, függetlenül a jogi formájától, ideértve a
      rendszeres gazdasági tevékenységet folytató személyegyesítő társaságokat és
      egyesületeket is;
    </p>
    <h1>
      3. AZ ADATKEZELÉSRE VONATKOZÓ ELVEK ISMERTETÉSE
    </h1>
    <p>
      Az Adatkezelő a személyes adatokat jogszerűen és tisztességesen, az
      érintettek számára átlátható módon, a jelen Szabályzatban, ill. az annak
      mellékletét képező dokumentumokban meghatározott, egyértelmű és jogszerű
      célokból kezeli („<strong>célhoz kötöttség elve szerint</strong>”). Az
      adatkezelés az Adatkezelő céljainak eléréséhez szükséges mértékre
      korlátozódik („<strong>adattakarékosság</strong>”). A pontosság elvének
      megfelelően Adatkezelő biztosítja, hogy az általa kezelt személyes adatok
      naprakészek, ennek érdekében Adatkezelő minden ésszerű intézkedést megtesz,
      hogy az adatkezelés céljai szempontjából pontatlan személyes adatokat
      haladéktalanul törölje vagy helyesbítse („<strong>pontosság elve</strong>
      ”). Adatkezelő tudomásul veszi, hogy a személyes adatokat csak a céljainak
      eléréséhez szükséges ideig tárolhatja („ <strong>korlátozott tárolhatóság elve</strong>”). Adatkezelő az adatok
      kezelését oly módon végzi, hogy a megfelelő technikai vagy szervezési
      intézkedések alkalmazásával biztosítva legyen a személyes adatok megfelelő
      biztonsága, ideértve az adatok jogosulatlan vagy jogellenes kezelésével,
      véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni
      védelmet is („<strong>integritás és bizalmas jelleg</strong>”). Adatkezelő
      a bemutatott elveknek való megfelelés igazolása érdekében az egyes
      adatkezelési műveleteiről belső adatkezelési nyilvántartásokat vezet („ <strong>elszámoltathatóság elve</strong>”).
    </p>
    <p>
      Ezen Szabályzatban foglalt elvek a személyes adatokkal kapcsolatos
      gyakorlatunkat ismertetik. Adatkezelési elveink érvényesek a papíralapú
      adatkezelésekre, valamint valamennyi, az Adatkezelő által üzemeltetett
      eszközre, weboldalra, ügyfélszolgálati platformra vagy egyéb online
      alkalmazásra, amely internetes hivatkozással vagy egyéb módon hivatkozik
      rájuk.
    </p>
    <h1>
      4. AZ ADATKEZELÉSSEL KAPCSOLATOS ÁLTALÁNOS INFORMÁCIÓK
    </h1>
    <p>
      Az érintett személyes adatait Adatkezelő az érintett által igénybe vett
      szolgáltatások biztosításához, illetve a felhasználó élményének javítása
      érdekében kezeli. Jelen tájékoztató az adatkezelő által az alább felsorolt
      tevékenységek kapcsán végzett adatkezelésről nyújt tájékoztatást:
    </p>
    <p>
      a) <strong>anja.hu</strong> oldalon történő vásárlások és az ott nyújtott
      szolgáltatások során,
    </p>
    <p>
      b) a hírlevelekre történő feliratkozás során,
    </p>
    <p>
      c) panaszkezelés során
    </p>
    <p>
      d) A társaság felé különféle kommunikációs csatornákon keresztül intézett
      megkeresések megválaszolása kapcsán
    </p>
    <p>
      e) A társaság által üzemeltetett weboldalakon sütiken keresztül történő
      adatkezelése kapcsán.
    </p>
    <h2>
      4.1. Általános információk az egyes fenti adatkezelések kapcsán:
    </h2>
    <p>
      <strong>a) </strong>
      <strong>
        Jelen Adatvédelmi és adatkezelési szabályzat szempontjából
        adatkezelőnek minősül:
      </strong>
    </p>
    <p>
      <a name="_Hlk70336820">Herman Pharma Kft.</a>
    </p>
    <p>
      Székhely: 2363 Felsőpakony, utca 806. hrsz
    </p>
    <p>
      Adószám: 24689258-2-13
    </p>
    <p>
      Cégjegyzékszám: 13 09 165671
      <br/>
      <br/>
      <strong></strong>
    </p>
    <p>
      <strong>a) </strong>
      <strong>
        Adatvédelmi tisztviselő, adatvédelemmel kapcsolatos kontakt:
      </strong>
    </p>
    <p>
      Kaszó Beáta
    </p>
    <p>
      Email : <a href="mailto:beata.kaszo@anja.hu">beata.kaszo@anja.hu</a>
    </p>
    <p>
      Telefon: +36 30 475 24 58
    </p>
    <p>
      c) <strong>személyes adatok kezelésének célja és jogalapja: </strong>egyes
      adatkezeléseknél alább, illetve a kapcsolódó mellékletben meghatározva;
    </p>
    <p>
      d)
      <strong>
        személyes adatok tárolásának időtartama vagy ezen időtartam
        meghatározásának szempontjai:
      </strong>
      egyes adatkezeléseknél meghatározva
    </p>
    <p>
      e) <strong>az érintettek</strong> a jelen Szabályzat 9. pontjában
      meghatározottak szerint jogosultak kérelmezni az Adatkezelőtől a rá
      vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését,
      törlését vagy kezelésének korlátozását és tiltakozhat az ilyen személyes
      adatok kezelése ellen, valamint élhet adathordozhatósági jogával is.
    </p>
    <p>
      Az Adatkezelő minden szükséges esetben előzetesen elvégezte, illetve
      alkalmazza az érdekmérlegelési tesztet, tekintettel van a szükségesség és
      arányosság követelményére, a fokozatosság elvére, az előzetes tájékoztatás
      követelményére.
    </p>
    <h1>
      5. AZ EGYES ADATKEZELÉSSEL KAPCSOLATOS INFORMÁCIÓK
    </h1>
    <p>
      <a name="oldalon-torteno-vasarlas-szolgaltatas-so"></a>
    </p>
    <h2>
      5.1. <strong>Regisztráció, valamint vásárlás az anja.hu honlapon</strong>
    </h2>
    <p>
      <strong>Az adatkezelés célja: </strong>
    </p>
    <p>
      - Online termékértékesítés, a vásárlás és a fizetés dokumentálása, a
      számviteli kötelezettség teljesítése,
    </p>
    <p>
      - a felhasználó, mint vásárló beazonosítása és a vele való kapcsolattartás,
    </p>
    <p>
      - megrendelt termék (illetve szolgáltatás) teljesítése,
    </p>
    <p>
      - számlakiállítás, a fizetés lebonyolításának lehetősége,
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Az érintettek köre: </strong>
      A társaság webshojában terméket vagy szolgáltatást vásárló ügyfelek.
    </p>
    <p>
      <strong>A kezelt adatok: </strong>
    </p>
    <p>
      Alapadatok: e-mail cím, telefonszám, vezeték- és keresztnév
    </p>
    <p>
      Számlázási cím(ek): vezetéknév, keresztnév, telefonszám, cím (ország,
      irányítószám, helyiség, utca, házszám)
    </p>
    <p>
      Szállítási cím(ek): kötelező: (vezetéknév, keresztnév, telefonszám, cím
      (ország, irányítószám, helyiség, utca, házszám)
    </p>
    <p>
      <strong>Az adatkezelés jogalapja</strong>
    </p>
    <p>
      Online termékértékesítés esetén az érintett hozzájárulása az, GDPR 6. cikk
      (1) bekezdés a és a GDPR 6. cikk (1) bekezdés a) pontja alapján, valamint
      szerződés teljesítése a GDPR 6. cikk (1) bekezdés b) pontja alapján
    </p>
    <p>
      A vásárlás és a fizetés dokumentálása, a számviteli kötelezettség
      teljesítése, valamint a számlakiállítás és a fizetés lebonyolítása során
      jogi kötelezettség teljesítése a GDPR 6. cikk (1) bekezdés c) pontja,
      valamint a Számvit. tv. 169.§ (2) bekezdése és az Áfa tv. 169.§ alapján.
    </p>
    <p>
      A felhasználó, mint vásárló beazonosítása és a vele való kapcsolattartás és
      a megrendelt termék (illetve szolgáltatás) teljesítése során az érintett
      hozzájárulása az, GDPR 6. cikk (1) bekezdés a, a GDPR 6. cikk (1) bekezdés
      a) pontja, és szerződés teljesítése a GDPR 6. cikk (1) bekezdés b) pontja
      alapján.
    </p>
    <p>
      <strong>Az adatkezelés időtartama</strong>
    </p>
    <p>
      A szerződés teljesítéséig, az érintett személy hozzájárulásának
      visszavonásáig, ennek hiányában az Adatkezelő az adatokat a vásárlás
      időpontját követően, a Ptk. 6:22. § alapján 5 év múlva törli.
    </p>
    <p>
      Ha az adatokat az Adatkezelő a Számv. tv. alapján köteles megőrizni, akkor
      az adatokat az Adatkezelő az érintett hozzájárulásától függetlenül csak a
      felhasználói fiók megszűnését követően 8 év múlva törli.
    </p>
    <p>
      A hozzájárulást az info@anja.hu e-mail címre küldött üzenettel bármikor
      vissza lehet vonni.
    </p>
    <p>
      <strong>Az adatok megismerésére jogosult személyek köre</strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      A társaság ügyfél kiszolgálással és számlázással foglalkozó alkalmazottai
    </p>
    <p>
      <strong>Adattovábbítás:</strong>
    </p>
    <p>
      <strong>Bankkártyával történő vásárlás esetén:</strong>
    </p>
    <p>
      Az online termékértékesítés, mint adatkezelési cél megvalósulásával
      összefüggésben az interneten, a Simple Pay igénybevételével lebonyolított
      bankkártyás vásárlásokkal kapcsolatos adatok a A SimplePay rendszer
      üzemeltetője és a SimplePay szolgáltatás nyújtója az OTP Mobil Szolgáltató
      Korlátolt Felelősségű Társaság (OTP Mobil Kft., a továbbiakban: Simple
      Pay). Az OTP Mobil Kft. (1143 Budapest, Hungária krt. 17-19.) bankkártya
      elfogadói hálózatán keresztül, a tranzakció pénzügyi lebonyolítása, a
      tranzakció biztonság és a tranzakciók nyomon követése céljából kerülnek
      továbbításra. A továbbított adatok köre: vezetéknév, keresztnév, e-mail
      cím, fizetési tranzakcióval kapcsolatos adatok.
    </p>
    <p>
      <strong>Kiszállítás esetén</strong>
    </p>
    <p>
      Termékek kiszállítása esetén a címzett neve, címe, telefonszáma és a
      rendelés értéke az alábbi címzettek felé kerülhet továbbításra:
    </p>
    <p>
      <strong>SPRINTER Futárszolgálat Korlátolt Felelősségű Társaság</strong>
    </p>
    <p>
      1097 Budapest, Táblás utca 39.
    </p>
    <p>
      <strong>
        DPD Hungária Futárpostai, Csomagküldő Szolgáltató Korlátolt Felelősségű
        Társaság
      </strong>
    </p>
    <p>
      1134 Budapest, Váci út 33. 2. emelet
    </p>
    <p>
      <strong>
        DHL Express Magyarország Szállítmányozó és Szolgáltató Kft.
      </strong>
    </p>
    <p>
      1185 Budapest, BUD Nemzetközi Repülőtér repülőtér 302. ép.
    </p>
    <p>
      <strong>GLS General Logistics Systems Hungary Kft.</strong>
    </p>
    <p>
      2351 Alsónémedi, GLS Európa utca 2.
    </p>
    <p>
      <strong>
        Adatfeldolgozók igénybevétele: Lásd adatfeldolgozók c. fejezet.
      </strong>
    </p>
    <h2>
      5.2. <strong>Panaszkezelés során megadott személyes adatok </strong>
    </h2>
    <p>
      <strong>Az adatkezelés célja:</strong>
      A társaság kapcsolatfelvételre használatos email címére ( info@anja.hu )
      érkező panasz körülményeinek kivizsgálása és pontos dokumentálása
      érdekében, hogy az Adatkezelő rendelkezésére álljanak azok a kérések és
      észrevételek, amelyek az Adatkezelő tevékenységével kapcsolatosak.
    </p>
    <p>
      A kommunikáció archiválásra kerül, így bármely utólagos kérdés vagy vita
      esetén az információk eredeti formájukban rendelkezésre állnak és szükség
      esetén az üggyel összefüggésben az Adatkezelő kapcsolatba tud lépni a
      felhasználóval.
    </p>
    <p>
      <strong>A kezelt adatok: </strong>
      Nem szükségszerű az összes alábbi adatot kezelni panasz esetén, de az
      alábbi adatok kerülhetnek az Adatkezelőhöz:
    </p>
    <p>
      a) a fogyasztó neve, lakcíme,
    </p>
    <p>
      b) a panasz előterjesztésének helye, ideje, módja,
    </p>
    <p>
      c) a fogyasztó panaszának részletes leírása, a fogyasztó által bemutatott
      iratok, dokumentumok és egyéb bizonyítékok jegyzéke,
    </p>
    <p>
      d) az Adatkezelő nyilatkozata a fogyasztó panaszával kapcsolatos
      álláspontjáról, amennyiben a panasz azonnali kivizsgálása lehetséges,
    </p>
    <p>
      e) a jegyzőkönyvet felvevő személy és a fogyasztó aláírása (kivéve, ha
      e-mailen, vagy telefonon terjesztették elő a panaszt),
    </p>
    <p>
      f) a jegyzőkönyv felvételének helye, ideje,
    </p>
    <p>
      <strong>Az adatkezelés jogalapja</strong>
      : A panaszkezelési tevékenységek során kezelt adatok kezelésének jogalapja
      az érintett hozzájárulása az, GDPR 6. cikk (1) bekezdés a, a GDPR 6. cikk
      (1) bekezdés a) pontja, és szerződés teljesítése a GDPR 6. cikk (1)
      bekezdés b) pontja alapján, jogi kötelezettség teljesítése a GDPR 6. cikk
      (1) bekezdés c) és a Fgy. tv. 17/A.§ alapján.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Az adatkezelés időtartama: </strong>
      Az Adatkezelő a panaszról felvett jegyzőkönyvet és a válasz másolati
      példányát öt évig köteles megőrizni, és azt az ellenőrző hatóságoknak
      kérésükre bemutatni.
    </p>
    <p>
      Ha az adatokat az Adatkezelő a Számv. tv. alapján köteles megőrizni, akkor
      az adatokat az Adatkezelő az érintett hozzájárulásától függetlenül csak a
      panaszbejelentés megtételét követő 8 év múlva törli.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Az adatok megismerésére jogosult személyek köre: </strong>
      A Társaság panaszkezeléssel foglalkozó munkavállalói
    </p>
    <p>
      <strong>Adattovábbítás: </strong>
      nincs<strong> </strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Adatfeldolgozók igénybevétele: </strong>
      Lásd adatfeldolgozók c. fejezet.<strong> </strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <h2>
      5.3. <strong>Hírlevélre történő feliratkozás során történő adatkezelés</strong>
    </h2>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Az adatkezelés célja: </strong>
      Hírlevelekre egyrészt a honlapon történő regisztrációval egyidejűleg, vagy
      regisztráció nélkül is fel lehet iratkozni. Az adatkezelés fő célja, hogy
      az érintettek részére az Adatkezelő marketing tartalmú megkereséseket
      küldjön. Az Adatkezelő felhasználhatja az adatokat marketingcélú
      kutatásokhoz és felmérésekhez. A vonatkozó jogszabályi előírásoknak
      megfelelően az Adatkezelő nyilvántartást vezet azon természetes
      személyekről, akik a hírlevél-szolgáltatásra feliratkoztak. A
      nyilvántartásban nem szereplő természetes személyek részére az Adatkezelő
      nem küld hírleveleket.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>A kezelt adatok: </strong>
      Név, email cím
    </p>
    <p>
      <strong>Az adatkezelés jogalapja: </strong>
      Hozzájárulás az Info. tv. 5.§ (1) a) pontja és a GDPR 6. cikk (1) bekezdés
      a) pontja, az Eker. tv. 13/A. §-a, valamint a Grt. 6. § (1) bekezdése
      alapján.
    </p>
    <p>
      <strong>Az adatkezelés időtartama: </strong>
      A Tiltakozási jog gyakorlásáig, illetve az önkéntes leiratkozásig.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Az adatok megismerésére jogosult személyek köre</strong>
      : A társaság hírlevelek küldését irányító alkalmazottai
    </p>
    <p>
      <strong>Adattovábbítás: </strong>
      Nincs
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Adatfeldolgozók igénybevétele: </strong>
      Lásd adatfeldolgozók c. fejezet.<strong> </strong>
    </p>
    <h2>
      5.4. <strong>Kapcsolatfelvétel során végzett adatkezelések</strong>
    </h2>
    <p>
      A társasággal kapcsolatba lehet lépni többféle kommunikációs csatornán
      keresztül, pl email, telefon, közösségi média. Telefonon történő megkeresés
      esetén a beszélgetésről hangfelvétel nem készül.
    </p>
    <p>
      <strong>Az adatkezelés célja: </strong>
      A társaság termékeivel, szolgáltatásaival kapcsolatban érdeklődők
      kiszolgálása, kérdéseik megválaszolása, termékvásárlási, életviteli
      tanácsadás.
    </p>
    <p>
      <strong>A kezelt adatok: </strong>
      A kapcsolatfelvétel során megadott személyes adatok jellemzően: Név, email
      cím, telefonszám, bizonyos esetekben a pontosabb életviteli tanácsadás
      miatt az érintett azonosításra alkalmas fényképet is közöl magáról.
    </p>
    <p>
      <strong>Az adatkezelés jogalapja: </strong>
      Az érintett hozzájárulása, a GDPR 6. cikk (1) bekezdés a) pontja alapján.
      Mivel a kapcsolatfelvételt minden esetben az érintett kezdeményezi, így
      személyes adatainak megadása önkéntesnek tekinthető, és az adatkezelésbe
      való beleegyezés adott.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Az adatkezelés időtartama: </strong>
      Az adott kérdés megválaszolásáig.
    </p>
    <p>
      <strong>Az adatok megismerésére jogosult személyek köre: </strong>
      A társaság ügyfél kiszolgálással megbízott alkalmazottai
    </p>
    <p>
      <strong>Adattovábbítás: </strong>
      nincs
    </p>
    <p>
      <strong>Az adatok megőrzésének ideje: </strong>
      A kérdés megválaszolásáig, ezek után az adatkezelő a kapott személyes
      adatokat törli.
    </p>
    <p>
      <strong>Adatfeldolgozók igénybevétele: </strong>
      Lásd adatfeldolgozók c. fejezet.
    </p>
    <h2>
      5.5. Süti (cookie) kezeléssel kapcsolatos adatkezelés
    </h2>
    <p>
      A sütik olyan rövid szöveges, betűkből és számokból álló fájlok, amelyeket
      a felhasználó által felkeresett webáruházak töltenek le a számítógép, mobil
      készülék vagy más berendezés böngészőjére. A süti a felhasználó eszköze és
      a Herman Pharma Kft weboldalát üzemeltető szerver vagy egy harmadik fél
      szervere felé elküldött kérése alapján telepítődhetnek. A cookie-kat 3
      nagyobb csoportba sorolhatjuk:
      <br/>
      <br/>
      <strong>Szükséges cookie-k:</strong>
      <br/>
      Annak érdekében, hogy a webáruház helyes működését biztosítani tudjuk,
      cookie-kat kell használunk. Azokat a sütiket nevezzük szükségesnek, amelyek
      nélkül a webáruház nem tudna megfelelően funkcionálni.
    </p>
    <p>
      A szükséges cookie-k szabályozhatják pl a következő funkciókat:
    </p>
    <p>
      - beléptető oldal megjelenjen-e újra vagy sem a látogatónak
    </p>
    <p>
      - kívánságlistára tett termékek listája
    </p>
    <p>
      - bolt aktuális nyelve, amit a vásárló tud állítani
    </p>
    <p>
      - stb
    </p>
    <p>
      <strong>Statisztikai cookie-k:</strong>
      <br/>
      A statiszikai cookie információt gyűjtenek arról, hogyan használják
      látogatóink webáruházunkat. Ezek a sütik nem tudják pontosan beazonosítani
      a felhasználót. A statiszikai cookie-k által gyűjtött információk közé
      tartoznak az oldalmegtekintések, a kattintások, a munkamenetek hossza, a
      látogatás időpontja, stb.
    </p>
    <p>
      <strong>Marketing cookie-k:</strong>
      <br/>
      A marketing cookie-k abban segítenek, hogy minél kellemesebb böngészési
      élményt tudjon nyújtani a webáruház a látogatói számára, többek között
      személyre szabott ajánlatok és hirdetések megjelenítésével.
    </p>
    <p>
      A <a href="http://www.anja.hu">www.anja.hu</a> weboldal látogatása során az
      alábbi sütik töltődnek be :
    </p>
    <table cellspacing="0" cellpadding="0" border="0">
      <tbody>
      <tr>
        <td colspan="6">
          <p>
            COOKIE policy-hez szükséges információk
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <strong>Cookie neve </strong>
          </p>
        </td>
        <td>
          <p>
            <strong>Cookie tulajdonosa </strong>
          </p>
        </td>
        <td>
          <p>
            <strong>Kategória </strong>
          </p>
        </td>
        <td>
          <p>
            <strong>Milyen célt szolgál </strong>
          </p>
        </td>
        <td>
          <p>
            <strong>Milyen adatokat gyjt </strong>
          </p>
        </td>
        <td>
          <p>
            <strong>Érvényességi id id </strong>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            _ga
          </p>
        </td>
        <td>
          <p>
            Google
          </p>
        </td>
        <td>
          <p>
            statisztikai
          </p>
        </td>
        <td>
          <p>
            https://developers.google.com/analytics/devguides
            /collection/analyticsjs/cookie-usage
          </p>
          <p>
            Ezen sütik segítségével vesszük számba az oldalunkon
            történ látogatásokat és a forgalom forrásait, hogy
            ily módon mérjük és javítsuk az oldalunk
            teljesítményét. A sütik segítenek nekünk
            meghatározni a legnépszerbb és legkevésbé népszer
            oldalakat, és segítségükkel figyeljük a látogatók
            mozgását az oldalon. A sütik által gyjtött
            információk aggregált jellegek, ezért anonimek.
            Amennyiben nem engedélyezi a sütik használatát, nem
            értesülünk róla, hogy felkereste az oldalunkat.
          </p>
        </td>
        <td>
          <p>
            Anonim adatokat gyjt
          </p>
        </td>
        <td>
          <p>
            23 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            _fbp
          </p>
        </td>
        <td>
          <p>
            Facebook
          </p>
        </td>
        <td>
          <p>
            Marketing
          </p>
        </td>
        <td>
          <p>
            https://developers.facebook.com/docs/marketing-
            api/conversions-api/parameters/fbp-and-fbc
          </p>
        </td>
        <td>
          <p>
            Egyedi azonosítót generál,hogy a felhasználó számára
            releváns hirdetéseket tudjon megjeleníteni
          </p>
        </td>
        <td>
          <p>
            2 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            websiteCookie sAccepted
          </p>
        </td>
        <td>
          <p>
            Angular.js
          </p>
        </td>
        <td>
          <p>
            szükséges
          </p>
        </td>
        <td>
          <p>
            A süti banner elfogadásának tényét rogzíti
          </p>
        </td>
        <td>
          <p>
            IP cím
          </p>
        </td>
        <td>
          <p>
            119 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            _gac_UA- 152956325-1
          </p>
        </td>
        <td>
          <p>
            Google
          </p>
        </td>
        <td>
          <p>
            statisztikai
          </p>
        </td>
        <td>
          <p>
            https://support.google.com/google-ads/answer /7521212
          </p>
        </td>
        <td>
          <p>
            Egyedi azonosítót generál, ami információt ad arról
            hogy a google fizetett hirdetésekre mikor kattintott a
            felhasználó
          </p>
        </td>
        <td>
          <p>
            2 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            SimplePay
          </p>
        </td>
        <td>
          <p>
            OTP Mobil Kft
          </p>
        </td>
        <td>
          <p>
            szükséges
          </p>
        </td>
        <td>
          <p>
            Vásárlás esetén adatot továbbítunk a fizetéshez.
          </p>
        </td>
        <td>
          <p>
            IP cím, név, termékek értéke
          </p>
        </td>
        <td>
          <p>
            11 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            _gid
          </p>
        </td>
        <td>
          <p>
            Google
          </p>
        </td>
        <td>
          <p>
            statisztikai
          </p>
        </td>
        <td>
          <p>
            https://developers.google.com/analytics/devguides
            /collection/analyticsjs/cookie-usage
          </p>
          <p>
            Ezen sütik segítségével vesszük számba az oldalunkon
            történ látogatásokat és a forgalom forrásait, hogy
            ily módon mérjük és javítsuk az oldalunk
            teljesítményét. A sütik segítenek nekünk
            meghatározni a legnépszerbb és legkevésbé népszer
            oldalakat, és segítségükkel figyeljük a látogatók
            mozgását az oldalon. A sütik által gyjtött
            információk aggregált jellegek, ezért anonimek.
            Amennyiben nem engedélyezi a sütik használatát, nem
            értesülünk róla, hogy felkereste az oldalunkat.
          </p>
        </td>
        <td>
          <p>
            Anonim adatokat gyjt
          </p>
        </td>
        <td>
          <p>
            11 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            api_token
          </p>
        </td>
        <td>
          <p>
            OpenCart
          </p>
        </td>
        <td>
          <p>
            szükséges
          </p>
        </td>
        <td>
          <p>
            így azonosítjuk a felhasználót a backend-en, ezzel tud
            leadni megrendelést
          </p>
        </td>
        <td>
          <p>
            csak egy egyedi tokent tárol
          </p>
        </td>
        <td>
          <p>
            1 óra
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            wishlist
          </p>
        </td>
        <td>
          <p>
            OpenCart
          </p>
        </td>
        <td>
          <p>
            szükséges
          </p>
        </td>
        <td>
          <p>
            itt tároljuk a felhasználó kívánságlistára rakott
            termékeit
          </p>
        </td>
        <td>
          <p>
            termékek azonosítóját tárolja
          </p>
        </td>
        <td>
          <p>
            12 hónap
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            currency
          </p>
        </td>
        <td>
          <p>
            OpenCart
          </p>
        </td>
        <td>
          <p>
            szükséges
          </p>
        </td>
        <td>
          <p>
            felhasználó pénzneme
          </p>
        </td>
        <td>
          <p>
            pénznem
          </p>
        </td>
        <td>
          <p>
            3 hét
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            language
          </p>
        </td>
        <td>
          <p>
            OpenCart
          </p>
        </td>
        <td>
          <p>
            szükséges
          </p>
        </td>
        <td>
          <p>
            felhasználó nyelve
          </p>
        </td>
        <td>
          <p>
            nyelv azonosító
          </p>
        </td>
        <td>
          <p>
            3 hét
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="6">
          <br/>
        </td>
      </tr>
      </tbody>
    </table>
    <h1>
      6. Az érintettek jogairól való tájékoztatás
    </h1>
    <p>
      <a name="tajekoztatashoz-hozzafereshez-jog"></a>
      <strong>
        A tájékoztatáshoz, valamint a kezelt személyes adatokhoz való
        hozzáféréshez való jog:
      </strong>
    </p>
    <p>
      Az érintett jogosult arra, hogy az Adatkezelőtől visszajelzést kapjon arra
      vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha
      ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes
      adatokhoz és a következő információkhoz hozzáférést kapjon:
    </p>
    <p>
      a) az adatkezelés céljai;
    </p>
    <p>
      b) az érintett személyes adatok kategóriái;
    </p>
    <p>
      c) azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a
      személyes adatokat közölték vagy közölni fogják, ideértve különösen a
      harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket;
    </p>
    <p>
      d) adott esetben a személyes adatok tárolásának tervezett időtartama, vagy
      ha ez nem lehetséges, ezen időtartam meghatározásának szempontjai;
    </p>
    <p>
      e) az érintett azon joga, hogy kérelmezheti az adatkezelőtől a rá vonatkozó
      személyes adatok helyesbítését, törlését vagy kezelésének korlátozását, és
      tiltakozhat az ilyen személyes adatok kezelése ellen;
    </p>
    <p>
      f) a valamely felügyeleti hatósághoz címzett panasz benyújtásának joga;
    </p>
    <p>
      g) ha az adatokat nem az érintettől gyűjtötték, a forrásukra vonatkozó
      minden elérhető információ;
    </p>
    <p>
      h) automatizált döntéshozatal ténye, ideértve a profilalkotást is, valamint
      legalább ezekben az esetekben az alkalmazott logikára és arra vonatkozó
      érthető információk, hogy az ilyen adatkezelés milyen jelentőséggel bír, és
      az érintettre nézve milyen várható következményekkel jár.
    </p>
    <p>
      Ha személyes adatoknak harmadik országba vagy nemzetközi szervezet részére
      történő továbbítására kerül sor, az érintett jogosult arra, hogy
      tájékoztatást kapjon a továbbításra vonatkozóan a megfelelő garanciákról.
    </p>
    <p>
      Az Adatkezelő az adatkezelés tárgyát képező személyes adatok másolatát az
      érintett rendelkezésére bocsátja. Az érintett által kért további
      másolatokért az Adatkezelő az adminisztratív költségeken alapuló, ésszerű
      mértékű díjat számíthat fel. Ha az érintett elektronikus úton nyújtotta be
      a kérelmet, az információkat az Adatkezelő széles körben használt
      elektronikus formátumban bocsátja rendelkezésre, kivéve, ha az érintett
      másként kéri.
    </p>
    <p>
      Az előző bekezdésben említett, másolat igénylésére vonatkozó jog nem
      érintheti hátrányosan mások jogait és szabadságait.
    </p>
    <p>
      <a name="helyesbites-joga"></a>
    </p>
    <p>
      <strong>Helyesbítés joga:</strong>
    </p>
    <p>
      Az Adatkezelő az érintett kérésére az érintettre nézve pontatlan személyes
      adatot indokolatlan késedelem nélkül helyesbíti. Az adatkezelés célját
      figyelembe véve az érintett jogosult arra, hogy kérje a hiányos személyes
      adatainak – egyebek mellett kiegészítő nyilatkozat útján történő –
      kiegészítését.
    </p>
    <p>
      <a name="torleshez-jog"></a>
      <strong>Törléshez („elfeledtetéshez való jog”):</strong>
    </p>
    <p>
      Az érintett az alábbi indokok valamelyikének fennállása esetén jogosult
      arra, hogy kérésére az Adatkezelő indokolatlan késedelem nélkül törölje a
      rá vonatkozó személyes adatokat:
    </p>
    <p>
      a) személyes adatokra már nincs szükség abból a célból, amelyből azokat
      gyűjtötték vagy más módon kezelték;
    </p>
    <p>
      b) az érintett visszavonja az adatkezelés alapját képező hozzájárulását, és
      az adatkezelésnek nincs más jogalapja;
    </p>
    <p>
      c) az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező
      jogszerű ok az adatkezelésre vagy amennyiben az adatkezelés közvetlen
      üzletszerzéshez kapcsolódna;
    </p>
    <p>
      d) a személyes adatokat jogellenesen kezelték;
    </p>
    <p>
      e) a személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami
      jogban előírt jogi kötelezettség teljesítéséhez törölni kell;
    </p>
    <p>
      f) a személyes adatok gyűjtésére információs társadalommal összefüggő
      szolgáltatások kínálásával kapcsolatosan került sor.
    </p>
    <p>
      Az adatok törlése nem kezdeményezhető, ha az adatkezelés szükséges:
    </p>
    <p>
      a) a véleménynyilvánítás szabadságához és a tájékozódáshoz való jog
      gyakorlása céljából;
    </p>
    <p>
      b) a személyes adatok kezelését előíró, az adatkezelőre alkalmazandó uniós
      vagy tagállami jog szerinti kötelezettség teljesítése, illetve közérdekből;
    </p>
    <p>
      c) megelőző egészségügyi vagy munkahelyi egészségügyi célokból, a
      munkavállaló munkavégzési képességének felmérése, orvosi diagnózis
      felállítása, egészségügyi vagy szociális ellátás vagy kezelés nyújtása,
      illetve egészségügyi vagy szociális rendszerek és szolgáltatások irányítása
      érdekében, uniós vagy tagállami jog alapján vagy egészségügyi szakemberrel
      kötött szerződés értelmében és ezen adatok kezelése olyan szakember által
      vagy olyan szakember felelőssége mellett történik, aki uniós vagy tagállami
      jogban, illetve az arra hatáskörrel rendelkező tagállami szervek által
      megállapított szabályokban meghatározott szakmai titoktartási kötelezettség
      hatálya alatt áll, illetve olyan más személy által, aki szintén uniós vagy
      tagállami jogban, illetve az arra hatáskörrel rendelkező tagállami szervek
      által megállapított szabályokban meghatározott titoktartási kötelezettség
      hatálya alatt áll;
    </p>
    <p>
      d) a népegészségügy területét érintő olyan közérdekből, mint a határokon át
      terjedő súlyos egészségügyi veszélyekkel szembeni védelem vagy az
      egészségügyi ellátás, a gyógyszerek és az orvostechnikai eszközök magas
      színvonalának és biztonságának a biztosítása, és olyan uniós vagy tagállami
      jog alapján történik, amely megfelelő és konkrét intézkedésekről
      rendelkezik az érintett jogait és szabadságait védő garanciákra, és
      különösen a szakmai titoktartásra vonatkozóan;
    </p>
    <p>
      e) a népegészségügy területét érintő közérdek alapján és ezen adatok
      kezelése olyan szakember által vagy olyan szakember felelőssége mellett
      történik, aki uniós vagy tagállami jogban, illetve az arra hatáskörrel
      rendelkező tagállami szervek által megállapított szabályokban meghatározott
      szakmai titoktartási kötelezettség hatálya alatt áll, illetve olyan más
      személy által, aki szintén uniós vagy tagállami jogban, illetve az arra
      hatáskörrel rendelkező tagállami szervek által megállapított szabályokban
      meghatározott titoktartási kötelezettség hatálya alatt áll;
    </p>
    <p>
      f) közérdekű archiválás, tudományos és történelmi kutatási célból vagy
      statisztikai célból, amennyiben a törléshez való jog valószínűsíthetően
      lehetetlenné tenné vagy komolyan veszélyeztetné ezt az adatkezelést <sup>1</sup>; vagy
    </p>
    <p>
      g) jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez.
    </p>
    <p>
      <a name="adatkezeles-korlatozasahoz-jog"></a>
      <strong>Az adatkezelés korlátozásához való jog:</strong>
    </p>
    <p>
      Az érintett kérésére az Adatkezelő korlátozza az adatkezelést, ha az alábbi
      feltételek valamelyike teljesül:
    </p>
    <p>
      a) az érintett vitatja a személyes adatok pontosságát, ez esetben a
      korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az
      érintett ellenőrizze a személyes adatok pontosságát;
    </p>
    <p>
      b) az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését, és
      ehelyett kéri azok felhasználásának korlátozását;
    </p>
    <p>
      c) az Adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés
      céljából, de az érintett igényli azokat jogi igények előterjesztéséhez,
      érvényesítéséhez vagy védelméhez; vagy
    </p>
    <p>
      d) az érintett az Adatkezelő közérdeken vagy jogos érdeken alapuló
      adatkezelése kapcsán tiltakozott az adatkezelés ellen; ez esetben a
      korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül,
      hogy az adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos
      indokaival szemben.
    </p>
    <p>
      Ha az adatkezelés a fentiek alapján korlátozás alá esik, az ilyen személyes
      adatokat a tárolás kivételével csak az érintett hozzájárulásával, vagy jogi
      igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más
      természetes vagy jogi személy jogainak védelme érdekében, vagy az Unió,
      illetve valamely tagállam fontos közérdekéből lehet kezelni.
    </p>
    <p>
      Az Adatkezelő azon érintettet, akinek a kérésére a fentiek alapján
      korlátozta az adatkezelést, az adatkezelés korlátozásának feloldásáról
      előzetesen tájékoztatja.
    </p>
    <p>
      <a name="adathordozhatosaghoz-jog"></a>
      <strong>Adathordozhatósághoz való jog:</strong>
    </p>
    <p>
      Az érintett jogosult arra, hogy a rá vonatkozó, általa az Adatkezelő
      rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt,
      géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket
      az adatokat egy másik adatkezelőnek továbbítsa anélkül, hogy ezt
      akadályozná az az adatkezelő, amelynek a személyes adatokat a
      rendelkezésére bocsátotta, ha:
    </p>
    <p>
      a) az adatkezelés hozzájáruláson vagy szerződésen alapul; és
    </p>
    <p>
      b) az adatkezelés automatizált módon történik.
    </p>
    <p>
      Az adatok hordozhatóságához való jog fentiek szerinti gyakorlása során az
      érintett jogosult arra, hogy – ha ez technikailag megvalósítható – kérje a
      személyes adatok adatkezelők közötti közvetlen továbbítását.
    </p>
    <p>
      Az adathordozhatósághoz való jog gyakorlása nem sértheti a törléshez
      („elfeledtetéshez”) való jogot. Az említett jog nem alkalmazandó abban az
      esetben, ha az adatkezelés közérdekű vagy az adatkezelőre ruházott
      közhatalmi jogosítványai gyakorlásának keretében végzett feladat
      végrehajtásához szükséges.
    </p>
    <p>
      Az adathordozhatósághoz való jog nem érintheti hátrányosan mások jogait és
      szabadságait.
    </p>
    <p>
      <a name="tiltakozashoz-jog"></a>
      <strong>Tiltakozáshoz való jog:</strong>
    </p>
    <p>
      Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból
      bármikor tiltakozzon személyes adatainak az Adatkezelő általi kezelése
      ellen, amennyiben az adatkezelés jogalapja közérdek vagy az Adatkezelőre
      ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat
      végrehajtása, vagy az Adatkezelő vagy egy harmadik fél jogos érdekeinek
      érvényesítésének szükségessége, ideértve az említett rendelkezéseken
      alapuló profilalkotást is. Ebben az esetben az Adatkezelő a személyes
      adatokat nem kezelheti tovább, kivéve, ha bizonyítja, hogy az adatkezelést
      olyan kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek
      az érintett érdekeivel, jogaival és szabadságaival szemben, vagy amelyek
      jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez
      kapcsolódnak.
    </p>
    <p>
      Ha a személyes adatok kezelése közvetlen üzletszerzés érdekében történik,
      az érintett jogosult arra, hogy bármikor tiltakozzon a rá vonatkozó
      személyes adatok e célból történő kezelése ellen, ideértve a profilalkotást
      is, amennyiben az a közvetlen üzletszerzéshez kapcsolódik. Ha az érintett
      tiltakozik a személyes adatok közvetlen üzletszerzés érdekében történő
      kezelése ellen, akkor a személyes adatok a továbbiakban e célból nem
      kezelhetők.
    </p>
    <p>
      Ha a személyes adatok kezelésére tudományos és történelmi kutatási célból
      vagy statisztikai célból kerül sor, az érintett jogosult arra, hogy a saját
      helyzetével kapcsolatos okokból tiltakozhasson a rá vonatkozó személyes
      adatok kezelése ellen, kivéve, ha az adatkezelésre közérdekű okból végzett
      feladat végrehajtása érdekében van szükség.
    </p>
    <p>
      <a name="visszavonas-joga"></a>
      <strong>Visszavonás joga:</strong>
    </p>
    <p>
      Az érintett jogosult arra, hogy amennyiben az Adatkezelő adatkezelése az
      érintett hozzájárulásán alapul hozzájárulását bármikor visszavonja. A
      hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a
      visszavonás előtti adatkezelés jogszerűségét.
    </p>
    <p>
      <strong>
        Eljárás a fenti jogok gyakorlásával kapcsolatos érintetti kérelem
        esetén:
      </strong>
    </p>
    <p>
      Az Adatkezelő indokolatlan késedelem nélkül, de mindenféleképpen a kérelem
      beérkezésétől számított egy hónapon (30 napon) belül tájékoztatja az
      érintett a jelen Szabályzatban rögzített jogok gyakorlásával kapcsolatos
      érintetti kérelem nyomán hozott intézkedésekről. Szükség esetén, figyelembe
      véve a kérelem összetettségét és a kérelmek számát, ez a határidő további
      két hónappal meghosszabbítható.
    </p>
    <p>
      A határidő meghosszabbításáról az Adatkezelő a késedelem okainak
      megjelölésével a kérelem kézhezvételétől számított egy hónapon belül
      tájékoztatja az érintettet. Szükség esetén, figyelembe véve a kérelem
      összetettségét és a kérelmek számát, ez a határidő további két hónappal (60
      nappal) meghosszabbítható. Ha az érintett elektronikus úton nyújtotta be a
      kérelmet, a tájékoztatás lehetőség szerint elektronikus úton kerül
      megadásra, kivéve, ha az érintett azt másként kéri.
    </p>
    <p>
      Ha az Adatkezelő nem tesz intézkedéseket az érintett kérelme nyomán,
      késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított egy
      hónapon belül tájékoztatja az érintettet az intézkedés elmaradásának
      okairól, valamint arról, hogy az érintett panaszt nyújthat be valamely
      felügyeleti hatóságnál, és élhet bírósági jogorvoslati jogával.
    </p>
    <p>
      Az Adatkezelő a kért információkat és tájékoztatást díjmentesen biztosítja,
      azzal, hogy amennyibe az érintett kérelme egyértelműen megalapozatlan vagy
      – különösen ismétlődő jellege miatt – túlzó, az Adatkezelő figyelemmel a
      kért információ vagy tájékoztatás nyújtásával vagy a kért intézkedés
      meghozatalával járó adminisztratív költségekre ésszerű összegű díjat
      számolhat fel, vagy megtagadhatja a kérelem alapján történő intézkedést.
    </p>
    <p>
      Az Adatkezelő minden olyan címzettet tájékoztat az általa végzett
      valamennyi helyesbítésről, törlésről vagy adatkezelés-korlátozásról,
      akivel, illetve amellyel a személyes adatot közölték, kivéve, ha ez
      lehetetlennek bizonyul, vagy aránytalanul nagy erőfeszítést igényel. Az
      érintettet kérésére az Adatkezelő tájékoztatja e címzettekről.
    </p>
    <p>
      A rendszerben tárolt személyes adataiddal és az adatkezeléssel kapcsolatos
      bármely kérdésedet, kérésedet kérjük, juttasd el az info@anja.hu e-mail
      címre. Kérjük, tartsd szem előtt, hogy személyes adataid kezelésével
      kapcsolatban – az érdekedben - csak abban az esetben áll módunkban
      felvilágosítást adni, illetve intézkedést tenni, ha személyazonosságodat
      hitelt érdemlően igazoltad.
    </p>
    <p>
      A kérelmed megválaszolásához minden esetben szükségünk van az alábbi
      információkra:
    </p>
    <p>
      · regisztrációkor megadott email címed
    </p>
    <ul>
      <li>
        teljes neved
      </li>
    </ul>
    <p>
      · számlázási címed
    </p>
    <p>
      Kérjük ügyelj arra, hogy a regisztrációkor megadott email címről küldd el a
      megkeresést.
    </p>
    <h1>
      <a name="adatbiztonsagi-intezkedesek"></a>
      <strong>7. Adatbiztonsági intézkedések:</strong>
    </h1>
    <p>
      Az Adatkezelő, valamint a szerverhálózat üzemeltetője az adatokat az
      észszerűen hozzáférhető legkorszerűbb hardver- és szoftvertámogatás mellett
      védi különösen a jogosulatlan hozzáféréstől, megváltoztatástól,
      továbbítástól, nyilvánosságra hozataltól, törléstől vagy megsemmisítéstől,
      valamint a véletlen megsemmisülés és sérülés ellen, ezzel szolgálva az
      adatbiztonságot. Az Adatkezelő által kezelt adatokat főszabály szerint csak
      az Adatkezelőnek a jelen Szabályokban meghatározott adatkezelési célok
      megvalósításában résztvevő munkavállalói és egyéb közreműködői ismerhetik
      meg, akiket munkaszerződésük, illetve a foglalkoztatásukra vonatkozó
      jogviszonyuk, továbbá egyéb szerződéses jogviszonyuk, a jogszabályi
      rendelkezések, illetőleg az Adatkezelő utasítása alapján valamennyi,
      általuk megismert adat tekintetében titoktartási kötelezettség terhel.
    </p>
    <p>
      Az Adatkezelő minden adatkezelési tevékenységét pontosan dokumentálni kell.
      Az Adatkezelőnek valamennyi általa végzett adatkezelési tevékenységről (pl.
      hírlevél, webshop, munkavállalók nyilvántartása) nyilvántartást kell
      vezetnie. Az Adatkezelő az adattovábbítás jogszerűségének ellenőrzése,
      valamint az érintett tájékoztatása céljából adattovábbítási nyilvántartást
      vezet, amely tartalmazza a kezelt adatok továbbításának időpontját,
      jogalapot, címzettet, adatok körének meghatározását, az adatkezelést előíró
      jogszabályban meghatározott egyéb adatokat.
    </p>
    <h2>
      <a name="papiralapon-kezelt-adatok"></a>
      <strong>7.1 Papíralapon kezelt személyes adatok biztonsága</strong>
    </h2>
    <p>
      A papíralapon kezelt személyes adatok biztonsága érdekében az Adatkezelő az
      alábbi intézkedéseket alkalmazza:
    </p>
    <p>
      • az adatokat csak az arra jogosultak ismerhetik meg, azokhoz már nem
      férhet hozzá, más számára fel nem tárhatóak,
    </p>
    <p>
      • a dokumentumokat jól zárható, száraz, tűzvédelmi és vagyonvédelmi
      berendezéssel ellátott helyiségben helyezi el,
    </p>
    <p>
      • a folyamatos aktív kezelésben lévő iratokhoz csak az illetékesek
      férhetnek hozzá,
    </p>
    <p>
      • az adatkezelést végző munkatárs a nap folyamán csak úgy hagyhatja el az
      olyan helyiséget, ahol adatkezelés zajlik, hogy a rábízott adathordozókat
      elzárja, vagy az irodát bezárja,
    </p>
    <p>
      • amennyiben a papíralapon kezelt személyes adatok digitalizálásra
      kerülnek, Adatkezelő a digitálisan tárolt dokumentumokra irányadó
      biztonsági szabályokat alkalmazza, és ezt követeli meg adatfeldolgozóitól
      is.
    </p>
    <h2>
      <a name="digitalisan-tarolt-adatok"></a>
      <strong>7.2 A digitálisan tárolt személyes adatok biztonsága</strong>
    </h2>
    <p>
      A számítógépen, illetve hálózaton tárolt személyes adatok biztonsága
      érdekében az Adatkezelő, az alábbi intézkedéseket hozza:
    </p>
    <p>
      · az webshop rendszerében tárolt adatokhoz csak érvényes, személyre szóló,
      azonosítható jogosultsággal – legalább felhasználói névvel és jelszóval –
      lehet csak hozzáférni,
    </p>
    <p>
      · az adatokkal történő minden hozzáférés nyomon követhetően naplózásra
      kerül,
    </p>
    <p>
      · a személyes adatokat kezelő hálózaton a vírusvédelemről folyamatosan
      gondoskodik,
    </p>
    <p>
      · a rendelkezésre álló számítástechnikai eszközökkel, azok alkalmazásával
      megakadályozza illetéktelen személyek hálózati hozzáférését
    </p>
    <br clear="all"/>
    <p>
      <a name="eljaras-jogok-gyakorlasaval-kapcsolatban"></a>
    </p>
    <h1>
      8. Adatfeldolgozók
    </h1>
    <p>
      Az Adatkezelő a tevékenysége ellátásához a jelen Szabályzatban nevesített
      Adatfeldolgozót veszi igénybe. Az Adatfeldolgozó önálló döntést nem hoz,
      kizárólag az Adatkezelővel kötött szerződés és a kapott utasítások szerint
      jogosult eljárni. Az Adatkezelő ellenőrzi az Adatfeldolgozó munkáját. Az
      Adatfeldolgozó további adatfeldolgozó igénybevételére csak az Adatkezelő
      előzetes írásbeli hozzájárulásával jogosult.
    </p>
    <table cellspacing="1" cellpadding="0" border="1">
      <tbody>
      <tr>
        <td colspan="2">
          <p align="center">
            <a name="_Hlk70601498"><strong>Adatfeldolgozó</strong></a>
          </p>
        </td>
        <td colspan="4">
          <p align="center">
            <strong>
              Milyen személyes adatokhoz fér hozzá? Milyen módon
              használhatja fel az adott személyes adatot (milyen
              tevékenységet végez az Adatkezelő részére)?
            </strong>
          </p>
        </td>
        <td>
          <p align="center">
            <strong>Mennyi ideig tárolhatja az adatokat?</strong>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <strong>
              SPRINTER Futárszolgálat Korlátolt Felelősségű Társaság
            </strong>
          </p>
          <p>
            1097 Budapest, Táblás utca 39.
          </p>
          <p>
            <strong>
              DPD Hungária Futárpostai, Csomagküldő Szolgáltató
              Korlátolt Felelősségű Társaság
            </strong>
          </p>
          <p>
            1134 Budapest, Váci út 33. 2. emelet
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>
              DHL Express Magyarország Szállítmányozó és Szolgáltató
              Kft.
            </strong>
          </p>
          <p>
            „1185” Budapest, BUD Nemzetközi Repülőtér repülőtér 302.
            ép.
          </p>
          <p>
            <strong>GLS General Logistics Systems Hungary Kft.</strong>
          </p>
          <p>
            2351 Alsónémedi, GLS Európa utca 2.
          </p>
        </td>
        <td colspan="4" width="259">
          <p>
            Ezen futárszolgálatok szolgáltatásait vesszük igénybe a
            vásárolt termékek kiszállításához. A futárszolgálatoknak a
            sikeres kézbesításhez, és az ügyfél értesítéséhez
            elengedhetetlenül szükséges adatokat adjuk át: Név, email
            cím, telefonszám, kézbesítési cím
          </p>
        </td>
        <td colspan="2" width="168">
          <p>
            A kézbesítési cél teljesülésig tárolja az adatokat.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <p>
            <strong>Számlázz.hu </strong>
          </p>
          <p>
            <strong>KBOSS.hu Kft.</strong>
          </p>
        </td>
        <td colspan="4">
          <p>
            Számlázó szoftverünket üzemeltetik, így rendszerükbe
            belekerülnek a számla kiállításához szükséges személyes
            adatok: Név, cím
          </p>
        </td>
        <td>
          <p>
            Jogi kötelezettség miatt a számla adatokat a számviteli
            törvényben meghatározott ideig kötelesek vagyunk megőrizni.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
        </td>
        <td>
          <p>
            Weboldalunk tárhelyszolgáltatója, valamint
            levelezőrendszerünk szolgáltatója. Ezek működéséhez
            szükséges feltételeket biztosítja, technikai lehetősége van
            az email adatokba való betekintésbe, de azt semmilyen
            körülmények között nem teszi meg.
          </p>
        </td>
        <td colspan="3">
          <p>
            Amíg élő szerződésünk van velük.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <p>
            <strong>Prog-Oak Kft.</strong>
          </p>
          <p>
            6724 Szeged, Kátay u. 24
          </p>
        </td>
        <td>
          <p>
            Weboldalunk fejlesztésével, karbantartásával megbízott
            vállalkozó. A weboldal adatbázisaiba való betekintésre
            technikailag van lehetősége, de azt semmilyen körülmények
            között nem teszi meg.
          </p>
        </td>
        <td colspan="3">
          <p>
            Amíg élő szerződésünk van velük.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <p>
            <strong>Prog-Oak Kft.</strong>
          </p>
          <p>
            6724 Szeged, Kátay u. 24
          </p>
        </td>
        <td>
          <p>
            Weboldalunk fejlesztésével, karbantartásával megbízott
            vállalkozó. A weboldal adatbázisaiba való betekintésre
            technikailag van lehetősége, de azt semmilyen körülmények
            között nem teszi meg.
          </p>
        </td>
        <td colspan="3">
          <p>
            Amíg élő szerződésünk van velük.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <p>
            <strong>MailChimp company</strong>
          </p>
          <p>
            Atlanta, Egyesült Államok
          </p>
        </td>
        <td>
          <p>
            A MailChimp alkalmazást használjuk hírlevelek küldésére,
            így a hírlevél szolgáltatásra feliratkozó ügyfeleink neve
            és email címe az alkalmazásban tárolásra kerül, és
            kizárólag az általunk történő hírlevél kiküldése céljából
            kerül felhasználásra.
          </p>
        </td>
        <td colspan="3">
          <p>
            Amíg élő szerződésünk van velük. Amennyiben másik
            szolgáltatót választunk, a MailChimp minden eddigi
            adatunkat törölni köteles.
          </p>
        </td>
      </tr>
      <tr height="0">
        <td width="175">
          <br/>
        </td>
        <td width="1">
          <br/>
        </td>
        <td width="1">
          <br/>
        </td>
        <td width="325">
          <br/>
        </td>
        <td width="1">
          <br/>
        </td>
        <td width="1">
          <br/>
        </td>
        <td width="203">
          <br/>
        </td>
      </tr>
      </tbody>
    </table>
    <h1>
      <a name="_Toc50457240">9. </a>
      Adatvédelmi incidens
    </h1>
    <p>
      Adatvédelmi incidens: a biztonság olyan sérülése, amely a továbbított,
      tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes
      megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy
      az azokhoz való jogosulatlan hozzáférést eredményezi.
    </p>
    <p>
      Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár a
      természetes személyek jogaira és szabadságaira nézve, az Adatkezelő
      indokolatlan késedelem nélkül, világosan és közérthetően tájékoztatja az
      érintettet az adatvédelmi incidensről.
    </p>
    <p>
      Az érintettet nem kell tájékoztatni, ha a következő feltételek bármelyike
      teljesül:
    </p>
    <p>
      a) az adatkezelő megfelelő technikai és szervezési védelmi intézkedéseket
      hajtott végre, és ezeket az intézkedéseket az adatvédelmi incidens által
      érintett adatok tekintetében alkalmazták, különösen azokat az
      intézkedéseket – mint például a titkosítás alkalmazása –, amelyek a
      személyes adatokhoz való hozzáférésre fel nem jogosított személyek számára
      értelmezhetetlenné teszik az adatokat;
    </p>
    <p>
      b) az adatkezelő az adatvédelmi incidenst követően olyan további
      intézkedéseket tett, amelyek biztosítják, hogy az érintett jogaira és
      szabadságaira jelentett magas kockázat a továbbiakban valószínűsíthetően
      nem valósul meg;
    </p>
    <p>
      c) a tájékoztatás aránytalan erőfeszítést tenne szükségessé. Ilyen
      esetekben az érintetteket nyilvánosan közzétett információk útján kell
      tájékoztatni, vagy olyan hasonló intézkedést kell hozni, amely biztosítja
      az érintettek hasonlóan hatékony tájékoztatását.
    </p>
    <h1>
      10. Jogorvoslati lehetőségek
    </h1>
    <p>
      a) Bármilyen, adatkezeléssel kapcsolatos kérdéssel, észrevétellel kereshető
      az Adatkezelő a jelen Tájékoztatóban megadott elérhetőségek valamelyikén.
    </p>
    <p>
      b) A Nemzeti Adatvédelmi és Információszabadság Hatóságnál (levelezési cím:
      1374 Budapest, Pf. 603., telefon: +36-1-391-1400, email:
      ugyfelszolgalat@naih.hu, honlap: www.naih.hu) bejelentéssel vizsgálatot
      kezdeményezhet arra hivatkozással, hogy személyes adatai kezelésével
      kapcsolatban jogsérelem érte vagy annak közvetlen veszélye fennáll; illetve
    </p>
    <p>
      c) Az érintett a jogainak megsértése esetén az Adatkezelő ellen bírósághoz
      fordulhat. A bíróság az ügyben soron kívül jár el. Azt, hogy az adatkezelés
      a jogszabályban foglaltaknak megfelel, az Adatkezelő köteles bizonyítani. A
      per elbírálása a törvényszék hatáskörébe tartozik. A per – az érintett
      választása szerint – az érintett lakóhelye vagy tartózkodási helye szerinti
      törvényszék előtt is megindítható lakóhelye vagy tartózkodási helye
      szerinti törvényszék előtt is megindítható.
    </p>
  </section>
  <h3 (click)="back()" class="back padding-bottom">{{"MENU.BACK" | translate}}</h3>
</section>
