import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {PersistanceService} from '../persistance.service';
import {WishlistService} from '../wishlist.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  title = '';
  description = '';
  products = [];
  selectedCategory = '';

  constructor(
    private service: AppService,
    private translate: TranslateService,
    private persistance: PersistanceService,
    private wishlist: WishlistService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant',
    });
    if (this.title === '' && this.description === '') {
      this.route.params.subscribe(params => {
        this.selectedCategory = params.category;
        this.service.getCategory().subscribe(response => {
          Object.values(response.data.categories).forEach(category => {
            // @ts-ignore
            if (category.name === this.selectedCategory) {
              // @ts-ignore
              this.title = category.name;
              // @ts-ignore
              this.description = category.description;
              this.products = [];

              // @ts-ignore
              Object.values(category.products).forEach(product => {
                // @ts-ignore
                product.quantity = 0;
                this.products.push(product);
              });
            }
          });
        });
      });
    }
  }

  getProductSeo(seoUrl): string {
    return seoUrl.split(';').pop();
  }

  addToBasket(productId): void {
    this.service.addCart(productId, '', '').subscribe(addCartResponse => {
      const fbImage = document.createElement('img');
      fbImage.style.display = 'none';
      fbImage.style.width = '1px';
      fbImage.style.height = '1px';
      fbImage.src = 'https://www.facebook.com/tr?id=265138295569123&ev=AddToCart&cd[currency]=HUF&cd[value]=1.00&time=' + Date.now();
      const element = document.getElementById('facebook-add-to-cart');
      element.appendChild(fbImage);
      const anjaAlert = Swal.mixin({
        customClass: {
          title: 'anja-alert-title',
          confirmButton: 'anja-alert-button',
        }
      });

      anjaAlert.fire({
        icon: 'success',
        title: this.translate.instant('BASKET.ADDED_TO_BASKET_3'),
        toast: true,
        timerProgressBar: true,
        timer: 3000,
        position: 'center',
        focusConfirm: false,
        showConfirmButton: true,
        confirmButtonColor: '#3D3D3D',
        iconColor: '#0bbf74a6',
        confirmButtonText: this.translate.instant('LOGIN.OKAY'),
      });
    }, error => {
      this.service.refreshToken(this.persistance.get('logged_in_user'));
    });
  }

  public addWishlist(productId, loggedWishlist): void {
    if (!this.persistance.get('logged_in_user')) {
      if (!this.persistance.get('wishlist')) {
        this.persistance.set('wishlist', []);
      }
      this.wishlist.addToWishlist(productId);
    } else {
      if (!loggedWishlist) {
        this.service.addToWishlist(productId).subscribe(response => {
          console.log(response);
        }, error => {
          console.log(error);
        });
      } else {
        this.service.removeFromWishlist(productId).subscribe(response => {
          console.log(response);
        }, error => {
          console.log(error);
        });
      }

    }
  }

  checkWishList(productId, loggedWishlist): boolean {
    if (!this.persistance.get('logged_in_user')) {
      if (!this.persistance.get('wishlist')) {
        this.persistance.set('wishlist', []);
      }
      return !this.wishlist.checkWishlist(productId);
    } else {
      return !loggedWishlist;
    }
  }

}
